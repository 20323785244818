body.path-user{
  #block-views-block-recent-tips-block-2{
    text-align: center;
    h2{
      margin: 20px auto;
      font-size: 24px;
      font-family: Lato,sans-serif;
      font-weight: 900;
      text-transform: uppercase;
      padding: 0 0 5px;
      color: #fff;
      border-bottom: 4px solid #ffad10;
      display: inline-block;
    }
    > div{
      background-color: #fff;
      padding: 20px 25px;
      @include respond-to(ipad){
        //padding: 100px 25px;
      }
      &.contextual{
        padding: 0;
        background: transparent;
      }
      .create-tip-wrapper{
        /*a{
          display: inline-block;
          text-align: center;
          margin: 0 0 40px 0;
          color: #ffad10;
          font-size: 22px;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 700;
          padding: 0 0 5px;
          text-transform: uppercase;
          border-bottom: 4px solid transparent;
          &:hover{
            border-bottom: 4px solid #ffad10;
          }
        }*/
      }
    }
  }
  #block-qarea-content{
    .views-element-container{
      background-color: #fff;
      padding: 40px 25px;
      @include respond-to(ipad){
        //padding: 100px 25px;
      }
      header{
        h2{
          padding: 0 25px;
          text-transform: uppercase;
          text-align: center;
          margin: 0 0 40px 0;
          display: block;
          border: none;
        }
      }
      /*table, .cols-7, .cols-3{
        max-width: 830px;
        margin: 0 auto;
        table-layout: fixed;
        tr{
          th{
            vertical-align: top;
            font-size: 12px;
            color: #6d6d67;
            text-transform: uppercase;
            font-weight: 400;
            font-family: sans-serif;
            padding: 10px 0;
          }
          td{
            a{
              color: #6d6d67;
              &:hover{
                color: #ffad10;
              }
            }
            vertical-align: middle;
            padding: 15px 10px;
            color: #6d6d67;
            font-size: 12px;
            font-family: sans-serif;
            border-bottom: 1px solid #000;
            text-align: center;
          }
        }
        thead{
          tr{
            th{
              vertical-align: top;
              font-size: 12px;
              color: #6d6d67;
              text-transform: uppercase;
              font-weight: 400;
              font-family: sans-serif;
              padding: 10px 0;
            }
          }
        }
        tbody{
          tr{
            td{
              a{
                color: #6d6d67;
                &:hover{
                  color: #ffad10;
                }
              }
              vertical-align: middle;
              padding: 15px 10px;
              color: #6d6d67;
              font-size: 12px;
              font-family: sans-serif;
              border-bottom: 1px solid #000;
              text-align: center;
            }
          }
        }
      }*/
    }
  }
  #block-views-block-withdraw-history-for-agent-block-1{
    background-color: #fff;
    text-align: center;
    > h2{
      padding: 40px 15px 0;
      text-transform: uppercase;
      text-align: center;
      margin: 0 0 40px 0;
      font-size: 24px;
    }
    > div{
      padding: 0 25px 40px;
    }
  }
  #block-views-block-withdraw-history-block-1{
    background-color: #fff;
    text-align: center;
    padding-bottom: 40px;
    @include respond-to(ipad){
      padding-bottom: 100px;
    }
    > h2{
      padding: 100px 25px 0;
      text-transform: uppercase;
      text-align: center;
      margin: 0 0 40px 0;
      font-size: 24px;
    }
    > div{
      padding: 0 25px;
    }
  }
}
table.main_table{
  //max-width: 830px;
  width: 100%;
  margin: 0 auto;
  table-layout: fixed;
  thead{
    tr{
      th{
        font-size: 18px;
        color: #fff;
        font-weight: 900;
        font-family: Lato, sans-serif;
        text-transform: uppercase;
      }
    }
  }
  tr{
    th{
      vertical-align: top;
      font-size: 18px;
      color: #6d6d67;
      text-transform: uppercase;
      font-weight: 400;
      font-family: sans-serif;
      padding: 10px 0;
    }
    td{
      font-size: 16px;
      color: #363532;
      font-family: Lato, sans-serif;
      font-weight: 400;
      a{
        color: #ffad10;
        font-weight: 700;
        font-size: 18px;
        border-bottom: 1px solid transparent;
        &:hover{
          border-bottom: 1px solid #ffad10;
        }
      }
      vertical-align: middle;
      padding: 15px 10px;
      /*color: #6d6d67;
      font-size: 12px;
      font-family: sans-serif;*/
      border-bottom: 1px solid #ffad10;
      text-align: center;
    }
  }
  thead{
    tr{
      th{
        vertical-align: top;
        font-size: 12px;
        color: #6d6d67;
        text-transform: uppercase;
        font-weight: 400;
        font-family: sans-serif;
        padding: 10px 0;
      }
    }
  }
  tbody{
    tr{
      td{
        a{
          color: #ffad10;
          border-bottom: 1px solid transparent;
          &:hover{
            border-bottom: 1px solid #ffad10;
          }
        }
        vertical-align: middle;
        padding: 15px 10px;
        color: #6d6d67;
        font-size: 12px;
        font-family: sans-serif;
        border-bottom: 1px solid #ffad10;
        text-align: center;
      }
    }
  }
}