/*body{
  &.path-user{
    #block-qarea-breadcrumbs{
      padding: 20px 0;
    }
  }
}*/
#block-qarea-breadcrumbs{
  padding: 20px 0 0 0;
  nav.breadcrumb{
    ol{
      font-size: 0;
      li{
        display: inline-block;
        font-size: 12px;
        text-transform: uppercase;
        padding-bottom: 4px;
        a{
          display: inline-block;
          color: #be8d30;
          font-size: 12px;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          text-transform: uppercase;
        }
        &:before{
          content: ' / ';
          display: inline-block;
          color: #be8d30;
          font-size: 12px;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          text-transform: uppercase;
          padding: 0 10px;
        }
        &:first-child{
          &:before{
            content: '';
            display: none;
          }
        }
      }
    }
  }
}