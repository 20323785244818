body.path-user #block-qarea-content{
  &.user_tickets_tab_wrap_parent{
    background-color: #fff;
    padding: 20px 0;
    @include respond-to(ipad){
      //padding: 100px 0 170px;
    }
    .rs-carousel{
      display: none;
    }
  }
  .user_tickets_tab_wrap{
    display: block;
    margin: 20px auto 0;
    width: 100%;
    padding: 0 25px;
    max-width: 970px;
    overflow: hidden;
    //max-height: 425px;
    height: 100%;
    .left{
      float: left;
      max-width: 230px;
      width: 27%;
      text-align: center;
      display: inline-block;
      h2{
        text-align: left;
        display: block;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #6d6d67;
        margin: 0!important;
        text-transform: uppercase;
        border: 1px solid #e3e4e8;
        border-bottom: none;
        padding: 14px;
        position: relative;
        opacity: 0.99;
        cursor: pointer;
        &:after{
          content: ' + ';
          font-size: 34px;
          color: #6d6d67;
          z-index: 1;
          position: absolute;
          right: 15px;
          top: 1px;
        }
        &.opened{
          @include transition(all 200ms ease-in-out);
          margin: 0 0 20px 0!important;
          border: 1px solid #e3e4e8;
          &:after{
            content: ' - ';
            right: 10px;
          }
        }
      }
      .ticket_form{
        padding: 0 5px;
        display: block;
        text-align: left;
        margin: 0 0 65px 0;
        @include respond-to(ipad){
          padding: 0 10px;
        }
        h3{
          display: none;
        }
        form{
          &.node-ticket-form{
            .captcha{
              margin: 0 0 15px 0;
            }
          }
          .prefix_label{
            font-family: 'Ubuntu', sans-serif;
            font-size: 12px;
            font-weight: 400;
            color: #6d6d67;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
          .form-wrapper{
            margin: 0 0 20px 0;
            .form-item{
              label{}
              input[type=text]{
                color: #a9a9a3;
                border: none;
                font-family: Ubuntu,sans-serif;
                font-size: 12px;
                font-weight: 300;
                border-bottom: 1px solid #e3e4e8;
                background: url(../images/input_cr.png) 95% 50% no-repeat;
                box-shadow: none;
                margin: 0;
                padding: 10px 20px 10px 0;
                &:focus{
                  outline: none;
                }
              }
            }
            &.field--name-field-ticket-category{
              font-size: 0;
              label{
                display: inline-block;
                width: 30%;
                margin: 0 10% 0 0;
                font-family: 'Ubuntu', sans-serif;
                font-size: 12px;
                font-weight: 400;
                color: #6d6d67;
                text-transform: uppercase;
              }
              select{
                height: 30px;
                display: inline-block;
                width: 60%;
                border: none;
                font-family: Ubuntu,sans-serif;
                font-size: 12px;
                font-weight: 300;
                color: #a9a9a3;
                border-bottom: 1px solid #e3e4e8;
                border-radius: 0;
                box-shadow: none;
                &:focus{
                  outline: none;
                }
              }
            }
            &.form-actions{
              font-size: 0;
              text-align: left;
              @include respond-to(ipad){
                text-align: center;
              }
              a{
                display: block;
                color: #a9a9a3;
                font-size: 12px;
                font-family: 'Ubuntu', sans-serif;
                margin: 0 0 10px 0;
                @include respond-to(ipad){
                  display: inline-block;
                  margin: 0 30px 0 0;
                }
                &:hover{
                  color: #ffad10;
                }
              }
              input[type=submit]{
                display: inline-block;
                font-size: 12px;
                font-family: 'Ubuntu', sans-serif;
                padding: 12px 14px;
              }
            }
          }
          details{
            font-size: 12px;
            &.captcha{
              font-size: 12px;
              summary{
                margin: 0 0 5px 0;
              }
            }
            .form-item{
              label{}
              input[type=text]{
                font-size: 12px;
                &#edit-captcha-response{
                  margin: 0 0 5px 0;
                }
              }
              span.form-required{}
              #edit-captcha-response--description{
                font-size: 10px;
              }

            }
          }
        }
      }
      .tickets{
        > h3{
          margin: 0 0 5px 0;
        }
        .scroll-wrapper{
          height: 132px;
          overflow: hidden;
        }
        > div{
          //overflow-y: scroll;
          .views-row{
            padding: 5.9px 10px;
            border-top: 1px solid #e3e4e8;
            text-align: left;
            background-color: #fff;
            .views-field{
              .field-content{
                display: block;
                a{
                  display: block;
                  color: #6d6d67;
                  font-size: 14px;
                  font-family: 'Ubuntu', sans-serif;
                  font-weight: 400;
                  span.question_text{
                    display: block;
                    margin-bottom: 3px;
                    font-size: 14px;
                    font-family: 'Ubuntu', sans-serif;
                    font-weight: 500;
                  }
                }
              }
            }
            &:hover{
              background-color: #fff9ee;
            }
            &:nth-child(3), &:last-child{
              border-bottom: 1px solid #e3e4e8;
            }
          }
        }
      }
    }
    .right{
      float: left;
      width: 73%;
      display: inline-block;
      position: relative;
      padding-top: 44px;
      .closed_message {
        text-align: center;
      }
      .ticket_name{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        font-family: 'Ubuntu', sans-serif;
        //font-weight: 400;
        font-weight: 700;
        font-size: 14px;
        color: #5d4820;
        text-transform: uppercase;
        padding: 14px;
        background-color: #ffad10;
        border: 1px solid #ffad10;
      }
      .ticket_messages{
        padding: 10px 25px;
        position: relative;
        opacity: 0.99;
        //max-height: 328px;
        //height: 100%;
        height: 456px;
        border: 1px solid #e3e4e8;
        border-bottom: none;
        overflow: hidden;
        .ticket_name{
          display: none;
        }
        div{
          .views-row{
            overflow: hidden;
            .message_time{
              padding: 10px 10px 10px 0;
              float: left;
              width: 5%;
              font-size: 12px;
              color: #b6b6b5;
              font-weight: 400;
              font-family: 'Ubuntu', sans-serif;
            }
            .message.question{
              float: right;
              max-width: 450px;
              font-size: 0;
              width: 100%;
              .message_label{
                width: 5%;
                height: 0;
                text-indent: -9999px;
                &:before{
                  content: 'Q: ';
                  display: inline-block;
                  font-family: 'Ubuntu', sans-serif;
                  font-size: 14px;
                  text-transform: uppercase;
                  font-weight: 400;
                  color: #6d6d67;
                  margin: 0 20px 0 0;
                }
              }
              .message_body{
                /*-webkit-clip-path: polygon(0 0, 95% 1%, 100% 58%, 0 56%);
                clip-path: polygon(0 0, 95% 1%, 100% 58%, 0 56%);*/
                display: table;
                width: 100%;
                max-width: 460px;
                //min-height: 60px;
                font-family: 'Ubuntu', sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #6d6d67;
                background-color: #fff9ee;
                padding: 10px 60px 10px 40px;
                margin-bottom: 20px;
                &:before{
                  content: 'Q: ';
                  //display: inline-block;
                  font-family: 'Ubuntu', sans-serif;
                  font-size: 14px;
                  text-transform: uppercase;
                  font-weight: 400;
                  color: #6d6d67;
                  margin: 0 20px 0 0;
                  display: table-cell;
                  width: 5%;
                  padding-right: 15px;
                }
              }
            }
            &.message_answer_parent{
              .message_time{
                float: right;
              }
              .message.answer{
                float: left;
                max-width: 450px;
                font-size: 0;
                width: 100%;
                .message_label{
                  width: 5%;
                  height: 0;
                  text-indent: -9999px;
                  &:before{
                    content: 'A: ';
                    display: inline-block;
                    font-family: 'Ubuntu', sans-serif;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 400;
                    color: #6d6d67;
                    margin: 0 20px 0 0;
                  }
                }
                .message_body{
                  /*-webkit-clip-path: polygon(5% 0, 100% 0, 100% 58%, 0 56%);
                  clip-path: polygon(5% 0, 100% 0, 100% 58%, 0 56%);*/
                  display: table;
                  width: 100%;
                  font-family: 'Ubuntu', sans-serif;
                  font-size: 12px;
                  font-weight: 400;
                  color: #6d6d67;
                  max-width: 460px;
                  //min-height: 60px;
                  background-color: #f7f7f7;
                  padding: 10px 60px 10px 40px;
                  margin-bottom: 20px;
                  &:before{
                    content: 'A: ';
                    //display: inline-block;
                    font-family: 'Ubuntu', sans-serif;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 400;
                    color: #6d6d67;
                    margin: 0 20px 0 0;
                    display: table-cell;
                    width: 5%;
                    padding-right: 15px;
                  }
                }
              }
            }
          }
        }
      }
      .ticket_message_add_form{
        form.bticket-message-form{
          .form-item-message{
            margin-bottom: 20px;
            div{
              textarea{
                display: block;
                border: 1px solid #e3e4e8;
                /*border-top: 1px solid #e3e4e8;
                border-bottom: 1px solid #e3e4e8;*/
                max-width: 100%;
                width: 100%;
                padding: 20px 25px;
                font-family: Ubuntu,sans-serif;
                font-size: 12px;
                font-weight: 300;
                color: #a9a9a3;
                max-height: 96px;
                height: 100%;
                margin: 0;
              }
            }
          }
          .form-actions{
            text-align: right;
            .button{}
          }
        }
      }
    }
  }
}