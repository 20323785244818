#block-contentform{}
.block-node{
  background-color: #fff;
  padding: 40px 15px;
  @include respond-to(ipad){
    //padding: 100px 15px;
  }
  h2{
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-weight: 900;
    font-size: 22px;
    text-align: center;
    margin: 0;
  }
  form.node-bookmakers-form{
    max-width: 410px;
    margin: 0 auto;
    width: 100%;
    color: #6d6d67;
    select{
      font-size: 14px;
    }
    > .form-wrapper{
      margin: 0 0 25px 0;
      &:last-child{
        margin: 0;
      }
      .form-item{
        font-size: 0;
        label{
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 400;
          font-family: sans-serif;
          letter-spacing: -0.5px;
          display: block;
          width: 100%;
          @include respond-to(ipad){
            width: 30%;
            display: inline-block;
          }
        }
        input{
          font-size: 22px;
          text-transform: none;
          font-weight: 700;
          margin: 0;
          font-family: Lato,sans-serif;
          color: #6d6d67;
          padding: 5px;
          border: none;
          border-bottom: 1px solid #9b9b8e;
          background: 0 0;
          box-shadow: none;
          display: block;
          width: 100%;
          @include respond-to(ipad){
            width: 70%;
            display: inline-block;
          }
        }
        .description{
          display: block;
          margin-top: 10px;
          a{
            color: #ffad10;
          }
        }
      }
    }
    > .js-form-type-vertical-tabs{}
    #edit-actions{
      .dropbutton-wrapper{
        float: none;
        &.dropbutton-multiple{
          .dropbutton-widget{
            ul.dropbutton{
              li{
                &.dropbutton-action{
                  margin: 0 0 20px 0;
                }
                &.dropbutton-toggle{}
              }
            }
          }
        }
      }
    }
  }
}