body.page-node-type-tip{
  #block-qarea-content{
    article{
      div{
        color: #6d6d67;
        //font-size: 0;
        font-family: 'Ubuntu', sans-serif;
        &.div_wrap_tip{
          padding: 0 25px;
        }
        .tip_status{
          color: #fff;
          font-family: 'Ubuntu', sans-serif;
          font-size: 30px;
          font-weight: 500;
          text-transform: uppercase;
          text-align: center;
          padding: 30px 0;
          background-color: #ffad10;
        }
        .tip_header_wrap{
          max-width: 730px;
          width: 100%;
          margin: 0 auto 50px;
          padding: 20px 0;
          background-color: #ffad10;
          .tip_header{
            text-align: center;
            .tip_header_region{
              font-family: 'Ubuntu', sans-serif;
              font-size: 18px;
              font-weight: 500;
              text-transform: uppercase;
              color: #fff;
              margin: 0 0 20px 0;
            }
            .tip_header_type{
              &.Soccer{
                min-height: 30px;
                line-height: 30px;
                font-family: 'Ubuntu', sans-serif;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                color: #fff;
                background: url("/themes/qarea/images/ball.png") no-repeat 44% 30%;
                margin: 0 0 20px 0;
                &:before{
                  content: '';
                }
              }
            }
            .tip_header_date{
              color: #fff;
              font-family: 'Ubuntu', sans-serif;
              font-size: 18px;
              font-weight: 500;
              text-transform: uppercase;
            }
          }
        }
        .tip_name{
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 0;
          background-color: #ffad10;
          margin: 0 -25px 50px;
          width: auto;
          span{
            display: inline-block;
            font-family: Lato, sans-serif;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            color: #fff;
            margin-right: 30px;
            &:last-child{
              font-family: Lato, sans-serif, sans-serif;
              font-size: 18px;
              font-weight: 700;
              text-transform: uppercase;
              color: #fff;
            }
          }
        }
        .tip_left_right_wrap{
          max-width: 730px;
          width: 100%;
          margin: 0 auto;
        }
        .tip_left{
          @include respond-to(ipad){
            display: inline-block;
            width: 70%;
            float: left;
          }
          > div{
            font-size: 0;
            text-align: left;
            margin: 0 0 25px 0;
            width: 100%;
            @include respond-to(ipad){
              display: table;
            }
            > div{
              font-family: Lato, sans-serif;
              font-weight: 400;
              text-transform: uppercase;
              color: #a8a8a7;
              font-size: 14px;
              width: 50%;
              display: inline-block;
              vertical-align: top;
              time{
                font-family: Lato, sans-serif;
                font-weight: 700;
                text-transform: capitalize;
                color: #222220;
                font-size: 18px;
                @include respond-to(ipad){
                  width: 50%;
                  display: inline-block;
                }
              }
              &:last-child{
                font-family: Lato, sans-serif;
                font-weight: 700;
                text-transform: capitalize;
                color: #222220;
                font-size: 18px;
                width: 50%;
                padding: 0 0 0 20px;
              }
              @include respond-to(ipad){
                width: 42%;
                display: table-cell;
                vertical-align: middle;
              }
            }
          }
        }
        .tip_right{
          @include respond-to(ipad){
            display: inline-block;
            width: 30%;
            float: left;
          }
          > div{
            font-size: 0;
            text-align: left;
            margin: 0 0 25px 0;
            width: 100%;
            @include respond-to(ipad){
              display: table;
            }
            > div{
              font-family: Lato, sans-serif;
              font-weight: 400;
              text-transform: uppercase;
              color: #a8a8a7;
              font-size: 14px;
              width: 50%;
              display: inline-block;
              vertical-align: top;
              time{
                font-family: Lato, sans-serif;
                font-weight: 700;
                text-transform: capitalize;
                color: #222220;
                font-size: 18px;
                @include respond-to(ipad){
                  width: 50%;
                  display: inline-block;
                }
              }
              &:last-child{
                font-family: Lato, sans-serif;
                font-weight: 700;
                text-transform: capitalize;
                color: #222220;
                font-size: 18px;
                width: 50%;
                padding: 0 0 0 20px;
              }
              @include respond-to(ipad){
                width: 50%;
                display: table-cell;
                vertical-align: middle;
              }
            }
          }
        }
        .tip_content{
          .tip_main{
            padding: 0;
            max-width: 600px;
            margin: 0 auto 20px;
            width: 100%;
            position: relative;
            opacity: .99;
            .vs_vs{
              text-transform: uppercase;
              font-size: 34px;
              font-weight: 700;
              color: #6d6d67;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
            }
            article{
              text-align: center;
              h2{
                a{
                  color: #6d6d67;
                  span{
                    color: #6d6d67;
                    font-size: 18px;
                  }
                }
              }
              > div{
                padding: 0;
                //max-width: 600px;
                //margin: 0 auto;
                //width: 100%;
                &:last-child{
                  > div{
                    padding: 20px 0;
                    margin: 0 0 20px 0;
                    @include respond-to(ipad){
                      float: right;
                      margin: 0;
                    }
                    > div{
                      h2 + div{
                        margin: 10px 0;
                      }
                      &:first-child{
                        font-size: 18px;
                        font-weight: 700;
                        margin: 0 0 10px 0;
                      }
                      &:last-child{
                        h2{
                          display: none;
                          font-size: 18px;
                          color: #6d6d67;
                          border: none;
                          margin: 10px 0;
                          @include respond-to(ipad){
                            margin: 20px 0;
                          }
                          a{
                            div{}
                          }
                        }
                        > div{
                          &:last-child{
                            font-size: 18px;
                            color: #6d6d67;
                            text-transform: uppercase;
                            font-weight: 700;
                            border: none;
                          }
                        }
                      }
                    }
                    &:first-child{
                      @include respond-to(ipad){
                        float: left;
                      }
                    }
                    &:last-child{
                      float: none;
                      clear: both;
                      padding: 20px 0;
                      background-color: #ffad10;
                      > div{
                        font-size: 18px;
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        &:first-child{
                          margin: 0 0 10px 0;
                        }
                        &:last-child{
                          text-transform: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .tip-result{
            text-align: center;
            padding: 0;
            max-width: 600px;
            margin: 0 auto;
            width: 100%;
            @include respond-to(ipad){
              text-align: left;
            }
            > div{
              width: 100%;
              word-break: break-word;
              word-wrap: break-word;
              overflow: hidden;
              &:first-child{}
              &:nth-child(2){}
              &:nth-child(3){}
              &:last-child{
                //display: table;
                //width: 100%;
                > div{
                  &:first-child{
                    //width: 23%;
                    //display: table-cell;
                  }
                  &:last-child{
                    //width: 77%;
                    //display: table-cell;
                    padding: 10px 0 0 0;
                  }
                }
              }
              > div{
                font-size: 18px;
                font-weight: 700;
                display: inline-block;
                margin: 0 0 20px 0;
                &:first-child{
                  font-size: 14px;
                  font-weight: 400;
                  text-transform: uppercase;
                  margin: 0;
                  padding: 5px 15px;
                  @include respond-to(ipad){
                    margin: 0 40px 0 0;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
        .tip_author{
          max-width: 600px;
          margin: 0 auto;
          width: 100%;
          padding: 40px 0;
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
          a{
            &.author-link{
              width: 20%;
              text-align: right;
              text-transform: none;
              font-size: 14px;
              display: inline-block;
              padding: 0 10px 0 0;
            }
          }
          span{
            text-transform: none;
            font-size: 14px;
            display: inline-block;
            &:first-child{}
          }
        }
        &:before, &:after{
          content: '';
          display: table;
        }
        &:after{
          clear: both;
        }
      }
    }
  }
}