#header {
  /*height: 100px;
   background: black;
   margin: 0 auto 50px;*/
  //background-color: transparent;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 12;
  //transform: translate(0, 0);
  padding: 0;
  border-bottom: 1px solid #fff;
  max-width: 1920px;
  @include respond-to(ipad){
    border-bottom: 0;
    padding: 30px 0;
  }
  &:before{
    content: '';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(12,12,12,.7);
    z-index: 1;
  }
  #header-wrapper{
    //overflow: hidden;
    position: relative;
    z-index: 12;
    //line-height: 2.3;
    &:before, &:after{
      content: '';
      display: table;
    }
    &:after{
      clear: both;
    }
    > div{
      &:before, &:after{
        content: '';
        display: table;
      }
      &:after{
        clear: both;
      }
    }
    #block-qarea-branding{
      display: none;
      width: 10%;
      @include respond-to(ipad){
        display: block;
        float: left;
        width: 15%;
      }
      @include respond-to(large){}
      a{
        margin: 0;
        padding: 0;
        img{
          display: block;
          width: auto;
        }
      }
      .contextual{
        .contextual-links{
          li{
            a{
              margin: 0.25em 0;
              padding: 0.4em 0.6em;
            }
          }
        }
      }
    }
    #block-qarea-main-menu{
      line-height: 2;
      @include respond-to(ipad){
        line-height: 3;
        width: 52%;
        float: left;
      }
      @include respond-to(large){
        /* width: 52%; */
      }
      ul.menu-main{
        font-size: 0;
        text-align: center;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        @include respond-to(ipad){
          display: block;
          text-align: right;
        }
        li{
          display: inline-block;
          padding: 0 10px;
          width: 33.333%;
          border-right: 1px solid #fff;
          &:first-child{
            border-left: 1px solid #fff;
          }
          @include respond-to(ipad){
            width: auto;
            border: none;
            &:first-child{
              border: none;
            }
          }
          a{
            display: block;
            color: #fff;
            text-transform: uppercase;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 400;
            //font-size: 10px;
            font-size: 13px;
            padding: 10px 0;
            @include respond-to(ipad){
              padding: 0;
            }
            @include respond-to(desktop){
              //font-size: 13px;
              font-size: 16px;
            }
            @include respond-to(large){
              font-size: 16px;
            }
          }
        }
      }
    }
    #block-qarea-account-menu--2{
      /*width: 25%;*/
      display: none;
      text-align: right;
      @include respond-to(ipad){
        float: right;
        display: block;
      }
      > ul{
        font-size: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        > li{
          display: inline-block;
          height: 100%;
          padding: 0 5px;
          @include respond-to(desktop){
            margin: 0 20px 0 0;
            padding: 0 15px;
          }
          &.user_account_item{
            margin: 0;
            padding: 0;
            
            .account_links {
              position: relative;
            }
            a.user_account_ref{
              display: block;
              position: relative;
              //text-indent: -9999px;
              font-size: 0;
              width: 50px;
              height: 48px;
              border: 3px solid #fff;
              background-image: url(/themes/qarea/images/user_.png);
              background-repeat: no-repeat!important;
              background-position: center center;
              border-radius: 100%;
              background-color: #fff;
              background-size: 62px 60px;
              &:before{
                position: absolute;
                left: 50%;
                bottom: 100%;
                content: "My Account";
                font-size: 13px;
                line-height: 18px;
                font-weight: 700;
                height: 18px;
                text-align: center;
                width: 80px;
                color: #fff;
                display: block;
                transform: translate(-50%, -10px);
              }              
            }
            a.messages_count {
                display: inline-block;
                position: absolute;
                top: -8px;
                right: -2px;
                background-color: #f9071e;
                width: 20px;
                height: 20px;
                padding: 6px;
                line-height: 8px;
                border-radius: 100%;
                color: #fff;
                font-size: 12px;
            }
          }
           > a{
              display: block;
              font-size: 13px;
              color: #fff;
              text-transform: uppercase;
              font-family: 'Ubuntu', sans-serif;
              font-weight: 500;
              position: relative;
              opacity: 0.99;
              line-height: 3.5;
              padding: 0 10px 0 0;
             @include respond-to(desktop){
               font-size: 16px;
             }
          }
          &:last-child{
            //background-color: #e69c0e;
            //clip-path: polygon(0 20%, 100% 1%, 86% 100%, 3% 100%);
            background: url('/themes/qarea/images/bg_register.png') no-repeat 100% 100%;
            background-size: 100% 100%;
            a{
              transition: none;
            }
          }
        }
      }
    }
  }
}
.mobile_user_wrap{
  //padding: 20px;
  //float: left;
  flex: 1;
  @include respond-to(ipad){
    display: none;
  }
  #block-qarea-account-menu{
    display: inline-block;
    ul{
      li{
        padding: 5px 0;
        /*a[href='/user/register']{
          display: none;
        }*/
        a{
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 700;
          border-bottom: 1px solid #fff;
          line-height: 1.3;
          @include respond-to(ipad){
            line-height: 1.5;
          }
          &:hover{
            color: #fff;
            border-bottom: 1px solid #fff;
          }
        }
        &:last-child{
          //display: none;
        }
      }
    }
  }
}
.mobile_header_logo{
  //float: left;
  //padding: 10px 15px;
  display: block;
  flex: 1;
  text-align: center;
  @include respond-to(ipad){
    display: none;
  }
  img{
    width: auto;
    height: auto;
    max-width: 100%;
    display: inline-block;
  }
}
.mobile_button{
  display: block;
  flex: 1;
  text-align: right;
  //padding: 24px 15px;
  //float: right;
  @include respond-to(ipad){
    display: none;
  }
  span{
    margin-left: auto;
    width: 30px;
    height: 3px;
    display: block;
    margin-bottom: 5px;
    background-color: #ff7d0f;
    border-radius: 3px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

body{
  &.lang_fa{
    .mobile_user_wrap{
      #block-qarea-account-menu{
        text-align: right;
      }
    }
  }
}


