@charset "utf-8";

html, body{
  min-height: 100%;
  color: #6d6d67;
  min-width: 0;
}
h3{
  line-height: 1.3;
}
.container{
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1170px;
  width: 100%;
  &:before{
    content: '';
    display: table;
  }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}

.row{
  margin-left: -15px;
  margin-right: -15px;
  &:before{
    content: '';
    display: table;
  }
  &:after{
    content: '';
    display: table;
    clear: both;
  }
}

.clear{
  clear: both;
  float: none;
}

.layout-container{
  &.slided{
    position: fixed;
    @include respond-to(ipad){
      position: relative;
    }
  }
}

p{
  color: #6d6d67;
}

h1{
  display: inline-block;
  margin: 0 auto;
  color: #fff;
  font-size: 24px;
  font-family: Lato, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  padding: 0 0 5px 0;
  border-bottom: 4px solid #ffad10;
}
input[type=text], input[type=email]{
  text-transform: none!important;
}
textarea, input, select{
  box-shadow: none;
  &:focus{
    outline: none;
    box-shadow: none;
  }
}

.create-tip-wrapper{
  text-align: right;
  margin: 0 0 20px 0;
  @include respond-to(ipad){
    margin: 0 0 40px 0;
  }
  a{
    display: inline-block;
    text-align: center;
    color: #ffad10;
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    //padding: 0 0 5px;
    padding: 12px 40px;
    text-transform: uppercase;
    //border-bottom: 2px solid transparent;
    &:hover{
      //border-bottom: 2px solid #ffad10;
    }
    &.create-tip{
      border: 1px solid #ffad10;
      border-bottom: 1px solid #ffad10!important;
      padding: 12px 40px;
      &:hover{
        background-color: #ffad10;
        color: #fff!important;
      }
    }
  }
}
a{
  @include transition(all 200ms ease-in-out);
  color: #fff;
  &:hover{
    color: #ffad10;
  }
}

input[type=file], .button{
  padding: 6px 20px;
  background-color: #ffad10;
  font-size: 18px;
  color: #fff;
  font-family: Lato, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid transparent;
  @include respond-to(ipad){
    padding: 12px 40px;
  }
  @include transition(all 200ms ease-in-out);
  &:hover{
    background-color: #fff;
    color: #ffad10;
    border: 1px solid #ffad10;
  }
}

///////////////////////////////*** for different users ***/////////////////////////////////

body{
  img{
    max-width: 100%;
  }
  .user-profile-email{
    margin-top: 20px;
  }
  &.path-upcoming-events{
    .live-events-wrapper{
      h2{
        color: #fff!important;
      }
    }
  }
  &.page-node-type-article{
    &.path-node{
      #block-qarea-content{
        padding: 20px 0;
      }
    }
  }
  &.page-node-type-page{
    #block-qarea-content{
      article{
        img{
          max-width: 100%;
          height: auto;
        }
      }
    }
    &.path-node{
      #block-qarea-content{
        padding-top: 20px;
        padding-bottom: 20px;
        h2{
          margin: 0 0 20px 0;
        }
      }
    }
    #block-qarea-content{
      article{
        > div{
          > div{
            h1{
              margin-bottom: 20px;
              font-size: 18px;
              color: #6d6d67;
            }
          }
        }
      }
    }
  }
  &.page-node-type-deposit-request{
    #block-qarea-content{
      article{
        > div{
          &:last-child{
            > div{
              max-width: 640px;
              margin: 0 auto;
              width: 100%;
              font-size: 0;
              &:first-child{}
              &:nth-child(2){}
              &:nth-child(3){}
              &:nth-child(4){}
              &:nth-child(5){}
              &:last-child{}


              > div{
                display: inline-block;
                margin: 0 0 20px 0;
                &:first-child{
                  text-transform: uppercase;
                  font-weight: 700;
                  width: 60%;
                  font-size: 14px;
                  @include respond-to(ipad){
                    font-size: 18px;
                  }
                }
                &:last-child{
                  width: 40%;
                  font-size: 14px;
                  @include respond-to(ipad){
                    font-size: 18px;
                  }
                  a{
                    font-weight: 400;
                    font-size: 14px;
                    @include respond-to(ipad){
                      font-size: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.role--administrator{
    &.admin{
      #header{
        #header-wrapper{
          nav#block-qarea-account-menu--2{
            > ul{
              > li{
                &:last-child{
                  display: none;
                }
              }
            }
          }
        }
      }
      .top-region{
        .mobile_user_wrap{
          nav#block-qarea-account-menu{
            > ul{
              > li{
                &:last-child{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  &.logged_in{}
  &.admin{
    #block-qarea-account-menu--2{
      ul{
        li{
          &:nth-child(3){
            display: none!important;
          }
        }
      }
    }
    .mobile_user_wrap{
      #block-qarea-account-menu{
        ul{
          li{
            &:nth-child(3){
              display: none!important;
            }
          }
        }
      }
    }
  }
  &.anonymous{
    #header #header-wrapper #block-qarea-account-menu--2>ul>li:nth-child(2){
      background: url(/themes/qarea/images/bg_register.png) 100% 100% no-repeat;
      background-size: 100% 100%;
    }
    .form-item-legal-terms-and-conditions{}
    #block-qarea-account-menu--2{
      ul{
        li{
          &:nth-child(3){
            display: none!important;
          }
        }
      }
    }
    .mobile_user_wrap{
      #block-qarea-account-menu{
        ul{
          li{
            &:nth-child(3){
              display: none!important;
            }
          }
        }
      }
    }
  }
  &.path-best-tipsters-previous-month{
    #block-qarea-content{
      background-color: #fff;
      .views-element-container{
        padding: 0 25px;
      }
    }
  }
  &.path-all-events,
  &.path-live-events{
    #block-qarea-content{
      padding: 0;
      .live-events-wrapper{
        >h2{
          color: #fff;
        }
      }
    }
    .views-element-container{
      text-align: center;
      &.block-views-blocklive-channels-block-1{
        text-align: center;
        h2{
          @extend h1;
          margin-bottom: 20px;
        }
        .views-view-grid{
          margin: 0 -15px;
          &:before, &:after{
            content: '';
            display: table;
          }
          &:after{
            clear: both;
          }
          .views-row{
            &:before, &:after{
              content: '';
              display: table;
            }
            &:after{
              clear: both;
            }
            .views-col{
              padding: 20px 15px;
              width: 100%!important;
              float: none;
              @include respond-to(ipad){
                float: left;
                width: 50%!important;
              }
              .live-channel-page{
                background-color: #fff;
                text-align: left;
                padding: 20px 50px;
                overflow: hidden;
                a{
                  float: left;
                  @include transition(all 200ms ease-in-out);
                  padding-top: 10px;
                  &:last-child{
                    float: right;
                    border: 2px solid #ffad10;
                    padding: 10px 35px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #6b6d71;
                    font-weight: 600;
                    font-size: 18px;
                    font-family: Ubuntu, sans-serif;
                    &:hover{
                      background-color: #ffad10;
                      color: #fff;
                    }
                  }
                  img{
                    width: 185px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.page-node-type-front-page-main-slide{
    #block-qarea-content{
      background-color: transparent!important;
      article{
        position: relative;
        > div{
          text-align: center;
          > div{
            p{
              font-family: Ubuntu,sans-serif;
              font-weight: 300;
              font-size: 14px;
              line-height: 1.5;
              color: #fff;
            }
            &:first-child{
              @extend h1;
              margin-bottom: 20px;
            }
            &:nth-child(2){
              margin-bottom: 20px;
              > div{
                &:first-child{
                  display: none;
                }
                > img{
                  width: 100%;
                  max-width: 100%;
                  height: auto;
                  max-height: 490px;
                }
              }
            }
            &:nth-child(3){
              display: none;
            }
            &:nth-child(4){
              display: none;
            }
            &:nth-child(5){
              position: absolute;
              top: 50%;
              left: 2%;
              transform: translate(0, -50%);
              @include respond-to(ipad){
                left: 5%;
              }
              > div{
                &:first-child{
                  display: none;
                }
                &:last-child{
                  img{
                    width: 100%;
                    height: auto;
                    max-width: 50px;
                    @include respond-to(ipad){
                      max-width: 140px;
                    }
                  }
                }
              }
            }
            &:nth-child(6){
              position: absolute;
              top: 50%;
              right: 2%;
              transform: translate(0, -50%);
              @include respond-to(ipad){
                right: 5%;
              }
              > div{
                &:first-child{
                  display: none;
                }
                &:last-child{
                  img{
                    width: 100%;
                    height: auto;
                    max-width: 50px;
                    @include respond-to(ipad){
                      max-width: 140px;
                    }
                  }
                }
              }
            }
            &:nth-child(7){
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              > div{
                &:first-child{
                  display: none;
                }
                &:last-child{
                  display: block;
                  color: #fff;
                  text-transform: uppercase;
                  font-family: Lato, sans-serif;
                  font-weight: 700;
                  text-align: center;
                  font-size: 18px;
                  @include respond-to(ipad){
                    font-size: 26px;
                  }
                  time{
                    @extend h2;
                    color: #fff!important;
                    border-bottom: none!important;
                  }
                }
              }
            }
            &:nth-child(8){
              > p{
                font-family: Ubuntu,sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 1.5;
                color: #fff!important;
              }
            }
            &.ind{
              &_1{
                @extend h1;
                margin-bottom: 20px;
              }
              &_2{
                margin-bottom: 20px;
                > div{
                  &:first-child{
                    display: none;
                  }
                  > img{
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    max-height: 490px;
                  }
                }
              }
              &_3{
                display: none;
              }
              &_4{
                display: none;
              }
              &_5{
                position: absolute;
                top: 50%;
                left: 2%;
                transform: translate(0, -50%);
                @include respond-to(ipad){
                  left: 5%;
                }
                > div{
                  &:first-child{
                    display: none;
                  }
                  &:last-child{
                    img{
                      width: 100%;
                      height: auto;
                      max-width: 50px;
                      @include respond-to(ipad){
                        max-width: 140px;
                      }
                    }
                  }
                }
              }
              &_6{
                position: absolute;
                top: 50%;
                right: 2%;
                transform: translate(0, -50%);
                @include respond-to(ipad){
                  right: 5%;
                }
                > div{
                  &:first-child{
                    display: none;
                  }
                  &:last-child{
                    img{
                      width: 100%;
                      height: auto;
                      max-width: 50px;
                      @include respond-to(ipad){
                        max-width: 140px;
                      }
                    }
                  }
                }
              }
              &_7{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                > div{
                  &:first-child{
                    display: none;
                  }
                  &:last-child{
                    display: block;
                    color: #fff;
                    text-transform: uppercase;
                    font-family: Lato, sans-serif;
                    font-weight: 700;
                    text-align: center;
                    font-size: 18px;
                    @include respond-to(ipad){
                      font-size: 26px;
                    }
                    time{
                      @extend h2;
                      color: #fff!important;
                      border-bottom: none!important;
                    }
                  }
                }
              }
              &_8{
                > p{
                  font-family: Ubuntu,sans-serif;
                  font-weight: 300;
                  font-size: 14px;
                  line-height: 1.5;
                  color: #fff!important;
                }
              }
            }
          }
          /*&:last-child{
            padding-left: 0!important;
            padding-right: 0!important;
            div{
              display: block;
              z-index: 2;
              &.quickedit-field{
                div{
                  &:first-child{
                    display: none;
                  }
                  &:last-child{
                    img{}
                    a{}
                  }
                }
              }
              &:first-child{
                display: block;
                div{
                  &:first-child{
                    display: none;
                  }
                  &:last-child{
                    display: block;
                    img{
                      width: 100%;
                      max-width: 100%;
                      height: auto;
                    }
                  }
                }
              }
              &:nth-child(2){
                display: none;
                float: none;
                clear: both;
              }
              &:nth-child(3){
                display: none;
                float: none;
                clear: both;
              }
              &:nth-child(4){
                //float: left;
                position: absolute;
                top: 50%;
                left: 2%;
                transform: translate(0, -50%);
                @include respond-to(ipad){
                  left: 5%;
                }
                div{
                  display: block;
                  &:first-child{
                    display: none;
                  }
                  &:last-child{
                    img{
                      width: 100%;
                      height: auto;
                      max-width: 50px;
                      @include respond-to(ipad){
                        max-width: 140px;
                      }
                    }
                  }
                }
              }
              &:nth-child(5){
                //float: left;
                position: absolute;
                top: 50%;
                right: 2%;
                transform: translate(0, -50%);
                @include respond-to(ipad){
                  right: 5%;
                }
                div{
                  display: block;
                  &:first-child{
                    display: none;
                  }
                  &:last-child{
                    img{
                      width: 100%;
                      height: auto;
                      max-width: 50px;
                      @include respond-to(ipad){
                        max-width: 140px;
                      }
                    }
                  }
                }
              }
              &:nth-child(6){
                //float: left;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                div{
                  display: block;
                  color: #fff;
                  text-transform: uppercase;
                  font-family: Lato, sans-serif;
                  font-weight: 700;
                  text-align: center;
                  font-size: 18px;
                  @include respond-to(ipad){
                    font-size: 26px;
                  }
                  time{
                    font-size: 16px;
                    @include respond-to(ipad){
                      font-size: 24px;
                    }
                  }
                }
              }
            }
          }*/
        }
      }
    }
  }
  &.page-node-type-front-page-main-links{
    &.path-node{
      #block-qarea-content{
        background-color: transparent;
      }
    }
    #block-qarea-content{
      article{
        > div{
          > div{
            &:first-child{
              > div{
                &:first-child{
                  display: none;
                }
                &:last-child{
                  text-align: center;
                  img{
                    max-width: 360px;
                    max-height: 258px;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            &:nth-child(2){
              display: none;
            }
            &:nth-child(3){
              display: none;
            }
            &:nth-child(4){
              text-align: center;
              > div{
                &:first-child{
                  display: none;
                }
                &:last-child{
                  time{
                    @extend h2;
                    color: #fff;
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.role--anonymous{}
  &.path-user{
    #block-qarea-content{
      padding: 0;
    }
    &.not-front{
      #block-qarea-page-title{
        &.block-page-title-block{}
      }
    }
    &.with-subnav{
      #block-qarea-content{
        article{
          > div{
            text-align: center;
            background: #fff;
            padding: 20px 25px;
            text-transform: uppercase;
            color: #6d6d67;
            font-size: 18px;
            font-family: sans-serif;
            font-weight: 600;
            margin: 0 0 20px;
            > div{
              margin: 0 0 5px 0;
              &:last-child{
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  &.path-user{
    #header #header-wrapper #block-qarea-account-menu--2 > ul > li:last-child{
      //background: none;
      clip-path: none;
    }
  }
  &.lang_fa{
    &.logged_in{
      #header #header-wrapper #block-qarea-account-menu--2 > ul > li:last-child{
        background: none;
        clip-path: none;
      }
    }
  }
  &.logged_in{
    #header #header-wrapper #block-qarea-account-menu--2 > ul > li:last-child{
      background: none;
      clip-path: none;
    }
    &.path-user{
      #block-qarea-content{
        margin-top: 20px;
      }
    }
  }
  &.page-node-type-tip{
    #block-qarea-content{
      article{
        div{
          p{
            color: #6d6d67;
          }
        }
      }
    }
  }
  &.path-frontpage{
    .main-slideshow{
      box-shadow: 0 22px 171px 85px rgba(0,0,0,1);
    }
    #main{
      position: relative;
      @include respond-to(ipad){
        //margin-top: -113px;
      }
      #content{
        #block-qarea-content{
          margin: 0;
        }
      }
    }
    #content{
      #block-qarea-content{
        padding-top: 0;
        padding-bottom: 0;
        h2{
          color: #fff;
        }
        div{
          p{
            color: #fff;
          }
        }
      }
    }
  }
  &.role--authenticated{
    .layout-container{
      margin: 0;
      padding: 0;
    }
    &.role--administrator{}
  }
  &.path-frontpage{
    #main{
      .messages--status{
        top: 20%;
      }
    }
    .layout-container{
      //background: url('../images/main_banner.png')no-repeat 0 0;
      //background-size: 100% 50%;
    }
  }
  &.path-best-tipsters-current-month{
    .create-tip-block{
      padding: 20px 0;
    }
    #block-qarea-content{
      .views-element-container{
        background-color: #fff;
        padding: 20px 25px;
        @include respond-to(ipad){
          //padding: 100px 25px;
        }
        div{
          header{
            text-align: center;
            font-size: 0;
            margin: 0 0 10px 0;
            > a{
              font-family: 'Ubuntu', sans-serif;
              font-weight: 300;
              text-transform: uppercase;
              color: #6b6d71;
              font-size: 14px;
              padding: 10px 0 9px 0;
              margin: 0 20px 0 0;
              width: 50%;
              vertical-align: top;
              display: inline-block;
              border-bottom: 4px solid transparent;
              @include respond-to(ipad){
                //width: 25%;
                //padding: 10px 15px;
                width: auto;
                vertical-align: baseline;
              }
              &:hover{
                color: #6b6d71;
                font-weight: 700;
                border-bottom: 4px solid #ffad10;
              }
              &:last-child{
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  &.path-best-tipsters{
    #block-qarea-content{
      .views-element-container{
        background-color: #fff;
        padding: 40px 25px;
        @include respond-to(ipad){
          //padding: 100px 25px;
        }
        div{
          header{
            text-align: center;
            font-size: 0;
            margin: 0 0 30px;
            > a{
              padding: 10px 15px;
              width: auto;
              vertical-align: baseline;
              display: inline-block;
              font-size: 14px;
              color: #ffad10;
            }
          }
        }
      }
    }
  }
  &.path-recent-tips{
    #block-qarea-content{
      .views-element-container{
        background-color: #fff;
        padding: 40px 25px;
        @include respond-to(ipad){
          //padding: 100px 25px;
        }
      }
    }
  }
  &.page-node-type-lottery{
    .views-element-container{
      header{
        padding: 20px 0;
      }
      .main_table_wrapper{
        background-color: #fff;
        padding: 100px 0;
      }
    }
    #block-qarea-content{
      /*&.block-page-title-block{
        text-align: center;
        > h1.js-quickedit-page-title{
          display: inline-block!important;
        }
      }*/
      article{
        > div{
          > div{
            margin: 0 0 20px 0;
            &:first-child{}
            &:nth-child(2){}
            &:nth-child(3){}
            &:nth-child(4){}
            > div{
              display: inline-block;
              color: #6d6d67;
              font-size: 12px;
              text-transform: uppercase;
              font-family: sans-serif;
              font-weight: 700;
              &:first-child{
                margin: 0 20px 0 0;
                width: 100px;
              }
            }
          }
        }
      }
    }
    .lottery-winners{
      header{
        text-align: center;
        margin: 0 0 20px 0;
        h2{
          @extend h1;
          padding-top: 50px;
        }
      }
      .main_table_wrapper{
        background-color: #fff;
        padding: 100px 0;
      }
    }
    .lottery-participants{
      text-align: center;
      h2{
        @extend h1;
        padding-top: 50px;
        margin: 0 0 20px 0;
      }
      > div{
        footer{
          padding: 20px 0;
          a{
            display: inline-block;
            text-align: center;
            color: #ffad10;
            font-size: 18px;
            font-family: Ubuntu,sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            border: 1px solid #ffad10;
            border-bottom: 1px solid #ffad10!important;
            padding: 12px 40px;
            &:hover{
              background-color: #ffad10;
              color: #fff;
            }
          }
        }
        .main_table_wrapper{
          background-color: #fff;
          padding: 100px 0;
        }
      }
    }
    .views-element-container{
      &.block-views-blocklottery-participants-block-2{
        text-align: center;
        > h2{
          @extend h1;
          margin: 0 0 20px 0;
          padding-top: 50px;
        }
        > div{
          > div{
            header{
              a{
                display: inline-block;
                text-align: center;
                color: #ffad10;
                font-size: 18px;
                font-family: Ubuntu,sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                border: 1px solid #ffad10;
                border-bottom: 1px solid #ffad10!important;
                padding: 12px 40px;
                &:hover{
                  background-color: #ffad10;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  &.path-legal{
    #block-qarea-content{
      padding-top: 40px;
      //padding-bottom: 40px;
    }
  }
  &.path-lotteries{
    #block-qarea-page-title{
      margin-top: 20px;
    }
    #block-qarea-content{
      background-color: #fff;
      .views-element-container{
        padding: 20px 25px;
        > div{
          width: 100%;
          @include respond-to(ipad){
            display: table;
            table-layout: fixed;
          }
        }
        .views-row{
          @include respond-to(ipad){
            display: table-row;
          }
          .views-field{
            padding: 10px 15px;
            text-align: left;
            color: #6d6d67;
            font-size: 12px;
            text-transform: uppercase;
            font-family: sans-serif;
            font-weight: 700;
            @include respond-to(ipad){
              display: table-cell;
              vertical-align: middle;
            }
            &:first-child{
              .field-content{
                display: inline-block;
                a{
                  text-transform: uppercase;
                }
              }
            }
            &:last-child{
              .field-content{
                display: inline-block;
              }
              a{
                display: inline-block;
                text-align: center;
                color: #ffad10;
                font-size: 18px;
                font-family: Ubuntu,sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                border: 1px solid #ffad10;
                border-bottom: 1px solid #ffad10!important;
                padding: 10px 15px;
                @include respond-to(desktop){
                  padding: 12px 40px;
                }
                &:hover{
                  background-color: #ffad10;
                  color: #fff;
                }
              }
            }
            &.views-field-field-lottery-start-date, &.views-field-field-lottery-finish-date{
              span.views-label{
                display: inline-block;
                margin: 0 20px 0 0;
              }
              .field-content{
                display: inline-block;
                time{
                  color: #6d6d67;
                  font-size: 12px;
                  text-transform: uppercase;
                  font-family: sans-serif;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
  #block-qarea-content{
    color: #6d6d67;
    font-family: Lato, sans-serif;
    font-size: 14px;
    h2{
      display: inline-block;
      margin: 20px auto;
      //color: #fff;
      font-size: 24px;
      font-family: Lato,sans-serif;
      font-weight: 900;
      border-bottom: 4px solid #ffad10;
      color: #6d6d67;
      text-transform: uppercase;
      padding: 0 0 5px;
    }
    form.node-bookmakers-edit-form{
       padding: 40px 25px;
       background-color: #fff;
       @include respond-to(ipad){
         //padding: 100px 25px;
       }
       > div{
         .form-item{
           label{
             float: left;
           }
           input[type=text]{
             float: left;
           }
           &:before, &:after{
             content: '';
             display: table;
           }
           &:after{
             clear: both;
           }
         }
       }
       .field--name-title{
         .form-item{
           label{}
           input[type=text]{}
         }
       }
       .field--name-field-site-url{}
       .field--name-field-login{}
       .field--name-field-password{}
       .field--name-field-to-user{}
       .form-actions{
         #edit-delete{
           color: #fff;
           &:hover{
             color: #ffad10;
           }
         }
       }
     }
  }
  #block-useragentblock{
    background-color: #fff;
    margin: 20px 0;
    padding: 40px 25px;
    text-align: center;
    @include respond-to(ipad){
      padding: 100px 160px;
    }
    &:before, &:after{
      content: '';
      display: table;
    }
    &:after{
      clear: both;
    }
    > h2{
      font-family: Lato,sans-serif;
      font-weight: 900;
      text-align: left;
      padding-bottom: 9px;
      text-transform: uppercase;
      color: #6d6d67;
      display: inline-block;
      border-bottom: 2px solid #ffad10;
      font-size: 18px;
      margin: 0 0 40px;
      &:last-child{
        margin-top: 50px;
      }
    }
    p{
      line-height: 1.5;
      color: #6d6d67;
      font-size: 14px;
      font-family: Lato, sans-serif;
      font-weight: 400;
    }
    a{
      font-size: 18px;
      text-transform: uppercase;
      font-family: Lato, sans-serif;
      font-weight: 700;
      color: #fff;
      &.agent-link{
        float: right;
        display: inline-block;
        background-color: #ffad10;
        padding: 12px 40px;
        border: 1px solid transparent;
        &:hover{
          border: 1px solid #ffad10;
          background-color: #fff;
          color: #ffad10;
        }
      }
    }
  }
  .views-element-container{
    &.block-views-blocksafebet-banners-block-1{
      > div{
        > div{
          .views-row{
            text-align: center;
            max-width: 730px;
            width: 100%;
            margin: 0 auto 30px;
            position: relative;
            min-height: 260px;
            padding-top: 179px;
            background-color: #fff;
            .views-field{
              &.views-field-field-safebet-banner{
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                .field-content{
                  .safebet-banner{
                    &.win{
                      border-top: 8px solid #51ecb0;
                    }
                    &.lose{
                      border-top: 8px solid #f23f3f;
                    }
                    &.live{
                      border-top: 8px solid #ffad10;
                    }
                  }
                }
                img{
                  width: 100%;
                  max-height: 179px;
                  height: 150px;
                  @include respond-to(ipad){
                    height: 179px;
                  }
                }
              }
              &.views-field-title{
                display: inline-block;
                color: #222220;
                font-family: Ubuntu, sans-serif;
                font-size: 30px;
                font-weight: 500;
                text-transform: uppercase;
                margin: 0 10px 0 0;
                padding: 25px 0 0 0;
                span{
                  a{
                    display: inline-block;
                    color: #222220;
                    font-family: Ubuntu, sans-serif;
                    font-size: 30px;
                    font-weight: 500;
                    text-transform: uppercase;
                    &:after{
                      content: ' - ';
                      display: inline-block;
                      margin: 0 0 0 10px;
                    }
                  }
                }
              }
              &.views-field-field-game-status{
                display: inline-block;
                font-family: Ubuntu, sans-serif;
                font-size: 30px;
                font-weight: 500;
                text-transform: uppercase;
                .field-content{
                  span{
                    &.status{
                      &.win{
                        color: #51ecb0;
                      }
                      &.lose{
                        color: #f23f3f;
                      }
                      &.live{
                        color: #ffad10;
                      }
                    }
                  }
                }
              }
            }
          }
          nav.pager{
            text-align: center;
            padding: 40px 0;
            ul.pager__items{
              font-size: 0;
              li{
                display: inline-block;
                margin: 0 10px 0 0;
                &.pager__item{
                  &.pager__item--first{
                    display: none;
                  }
                  &.pager__item--previous{
                    text-transform: uppercase;
                    a{
                      padding: 5px 30px ;
                      text-align: center;
                      border: 1px solid #6d6d67;
                      &:hover{
                        border: 1px solid #ffad10;
                      }
                      span{
                        text-transform: uppercase;
                      }
                    }
                  }
                  &.pager__item--next{
                    text-transform: uppercase;
                    a{
                      padding: 5px 30px ;
                      text-align: center;
                      border: 1px solid #6d6d67;
                      &:hover{
                        border: 1px solid #ffad10;
                      }
                      span{
                        text-transform: uppercase;
                      }
                    }
                  }
                  &.pager__item--last{
                    display: none;
                  }
                  &.is-active{
                    margin: 0 15px 0 0;
                    a{
                      border-bottom: 1px solid #727272;
                    }
                  }
                }
                a{
                  display: inline-block;
                  font-size: 14px;
                  font-family: Ubuntu, sans-serif;
                  font-weight: 400;
                  text-transform: uppercase;
                  border-bottom: 1px solid transparent;
                }
              }
            }
          }
        }
      }
    }
  }
  #block-qarea-content{
    .views-element-container{
      .favorite-club-view{
        .views-row{
          .views-field{
            &.views-field-field-club{
              color: #ffad10;
              font-size: 22px;
              font-family: Ubuntu, sans-serif;
              font-weight: 700;
              text-transform: none;
              text-align: center;
              margin: 0 0 20px 0;
              @include respond-to(ipad){
                margin: 0 0 40px 0;
              }
            }
            &.views-field-field-club-image{
              text-align: center;
              margin: 0 0 20px 0;
              @include respond-to(ipad){
                margin: 0 0 40px 0;
              }
              img{
                display: inline-block;
                max-width: 200px;
                max-height: 200px;
                width: 100%;
                height: auto;
              }
            }
            &.views-field-description__value{
              font-family: Lato, sans-serif!important;
              font-weight: 400!important;
              font-size: 18px!important;
              color: #363532!important;
              p{
                font-family: Lato, sans-serif!important;
                font-weight: 400!important;
                font-size: 18px!important;
                color: #363532!important;
              }
            }
          }
        }
      }
    }
  }
  #block-lastseasonclubstats{
    margin-top: 20px;
  }
  #block-lastseasonclubstats, .block-favclub-block{
    text-align: center;
    span.empty-message{
      display: block;
      margin: 0 0 20px 0;
    }
    > h2{
      @extend h1;
      margin: 0 0 20px 0;
    }
    > h3{
      text-transform: uppercase;
      font-family: Lato,sans-serif;
      font-weight: 900;
      font-size: 18px;
      text-align: center;
      margin: 0 0 20px;
      color: #fff;
    }
    .main_table_wrapper{
      padding: 40px 25px;
      background-color: #fff;
      margin: 0 0 20px 0;
      @include respond-to(ipad){
        //padding: 100px 25px;
      }
    }
    ul.cumulatives{
      background-color: #fff;
      padding: 40px 25px;
      margin: 0 0 20px 0;
      li{
        font-size: 0;
        width: 30%;
        margin: 0 auto;
        span{
          display: inline-block;
          font-family: Lato,sans-serif;
          font-weight: 400;
          text-transform: uppercase;
          color: #a8a8a7;
          font-size: 14px;
          width: 50%;
          text-align: left;
          &.label{}
          &.value{
            font-family: Lato,sans-serif;
            font-weight: 700;
            text-transform: capitalize;
            color: #222220;
            font-size: 18px;
            &:before{
              content: ' : ';
              display: inline-block;
              font-size: 18px;
              padding: 0 15px;
            }
          }
        }
      }
    }
  }
  &.path-best-tipsters-two-month-ago{
    #block-qarea-content{
      background-color: #fff;
      text-align: center;
      .views-element-container{
        padding: 0 25px;
      }
    }
  }
  #block-qarea-content, .views-element-container{
    .recent-tips-view{
      .main_table_wrapper{
        .main_table{
          tr{
            td{
              &.views-field-field-matches-team-a, &.views-field-field-matches-team-b{
                div{
                  h2{
                    display: none!important;
                  }
                  > div{
                    padding: 5px 0;
                    img{}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .recent-tips-view{
    .main_table_wrapper{
      .main_table{
        tr{
          td{
            &.views-field-field-matches-team-a, &.views-field-field-matches-team-b{
              div{
                h2{
                  display: none!important;
                }
                > div{
                  padding: 5px 0;
                  img{}
                }
              }
            }
          }
        }
      }
    }
  }
  header.rfilter_wrap{
    text-align: center;
    font-size: 0;
    margin: 0 0 30px;
  }
  .rfilter{
    font-family: Ubuntu,sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    color: #6b6d71!important;
    font-size: 14px;
    padding: 10px 0 9px!important;
    margin: 0 20px 0 0;
    width: auto;
    vertical-align: baseline;
    display: inline-block!important;
    border-bottom: 4px solid transparent;
    &.active{
      color: #6b6d71!important;
      font-weight: 700!important;
      border-bottom: 4px solid #ffad10!important;
    }
  }
  .rfilter:hover{
    color: #6b6d71!important;
    font-weight: 700;
    border-bottom: 4px solid #ffad10;
  }
}
.recent-tips-view{
  .main_table_wrapper{
    .main_table{
      tr{
        td{
          div{
            h2{
              a{
                //display: none;
              }
            }
          }
          &.views-field-field-matches-team-a, &.views-field-field-matches-team-b{
            div{
              h2{
                display: none;
              }
              > div{
                padding: 5px 0;
                img{}
              }
            }
          }
          &.views-field-field-matches-team-b{}
        }
      }
    }
  }
}
form.node-lottery-bid-form{
  padding: 40px 25px;
  max-width: 590px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  @include respond-to(ipad){
    padding: 100px 25px 75px;
  }
  .field--type-entity-reference{
    .form-item{
      margin: 0 0 20px 0;
      label{
        margin-right: 40px;
      }
      select#edit-field-select-lottery{
        border: none;
      }
    }
  }
}
nav.pager{
  text-align: center;
  padding: 40px 0;
  ul.pager__items{
    font-size: 0;
    li{
      display: inline-block;
      margin: 0 10px 0 0;
      &.pager__item{
        &.pager__item--first{
          display: none;
        }
        &.pager__item--previous{
          text-transform: uppercase;
          a{
            padding: 5px 30px ;
            text-align: center;
            border: 1px solid #6d6d67;
            &:hover{
              border: 1px solid #ffad10;
            }
            span{
              text-transform: uppercase;
            }
          }
        }
        &.pager__item--next{
          text-transform: uppercase;
          a{
            padding: 5px 30px ;
            text-align: center;
            border: 1px solid #6d6d67;
            &:hover{
              border: 1px solid #ffad10;
            }
            span{
              text-transform: uppercase;
            }
          }
        }
        &.pager__item--last{
          display: none;
        }
        &.is-active{
          margin: 0 15px 0 0;
          a{
            border-bottom: 1px solid #727272;
          }
        }
      }
      a{
        display: inline-block;
        font-size: 14px;
        font-family: Ubuntu, sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        border-bottom: 1px solid transparent;
      }
    }
  }
}
.block-views-blockagent-profile-points-block-1{
  display: none;
}
// duplicate from body.page-node-type-lottery
span.empty-message{
  color: #6d6d67;
  font-size: 14px;
  font-weight: 400;
  font-family: Ubuntu, sans-serif;
  text-transform: uppercase;
}
.lottery-winners{
  header{
    text-align: center;
    margin: 0 0 20px 0;
    h2{
      @extend h1;
      padding-top: 50px;
    }
  }
  .main_table_wrapper{
    background-color: #fff;
    padding: 100px 0;
  }
}
.lottery-participants{
  text-align: center;
  h2{
    @extend h1;
    padding-top: 50px;
    margin: 0 0 20px 0;
  }
  > div{
    header{
      margin: 0 0 20px 0;
      a{
        display: inline-block;
        text-align: center;
        color: #ffad10;
        font-size: 18px;
        font-family: Ubuntu,sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        border: 1px solid #ffad10;
        border-bottom: 1px solid #ffad10!important;
        padding: 12px 40px;
        &:hover{
          background-color: #ffad10;
          color: #fff;
        }
      }
    }
    .main_table_wrapper{
      background-color: #fff;
      padding: 100px 0;
    }
  }
}

.vertical-tabs{
  margin: 0;
  padding: 20px 0;
  border: none;
}
.vertical-tabs__menu{
  margin: 0;
  border: none;
}
.vertical-tabs__menu-item{
  border: none;
}
.messages--warning{
  opacity: .6;
  margin-bottom: 40px;
  font-size: 14px;
  position: relative;
  z-index: 999;
}
.messages--status{
  color: #fff;
  background-color: rgba(141, 190, 81, .9);
  border: none;
  left: 50%;
  font-size: 24px;
  line-height: 28px;
  margin: 0 -45% 0 0;
  padding: 50px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

#block-qarea-page-title{
  text-align: center;
  word-wrap: break-word;
  width: 100%;
  word-break: break-all;
}

// start section id=content for home page

#content{
  #block-qarea-page-title{
    //margin-top: 25px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  #block-views-block-agent-profile-points-block-1{
    padding: 20px 0;
    &.agent-points-block{
      .contextual-region{
        .views-row{
          text-align: center;
        }
      }
    }
  }

  #block-qarea-content{
    &.terms_and_conditions_wrap{
      background-color: #fff;
      padding-left: 25px;
      padding-right: 25px;
    }
    form.entity-legal-document-acceptance-form{
      padding: 20px 0;
      .form-item{
        margin: 0 0 20px 0;
        input[type=checkbox]{
          margin: 0 10px 0 0;
          padding: 0;
        }
        label{
          font-size: 12px;
          color: #fff;
          color: #6d6d67;
          &.option{
            color: #6d6d67;
          }
          a{
            display: inline-block;
            color: #ffad10;
            border-bottom: 1px solid transparent;
            &:hover{
              border-bottom: 1px solid #ffad10;
            }
          }
        }
      }
    }
    p{
      //color: #fff;
      color: #6d6d67;
      font-family: Ubuntu,sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.5;
    }
    article{
      float: none;
      .home_desc_text{
        max-width: 790px;
        margin: 0 auto;
        width: 100%;
        center{
          h1{
            margin: 0;
            color: #fff;
            font-size: 24px;
          }
        }
      }
      div{
        center{
          margin-bottom: 20px;
        }
        p{
          //color: #fff;
          color: #6d6d67;
          font-family: 'Ubuntu', sans-serif;
          font-weight: 300;
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }
    .inputTable{
      table{
        tr{
          td{
            color: #6d6d67;
          }
        }
      }
    }
  }

}

// end section id=content for home page

.agent-points-block{
  background-color: #fff;
  margin: 20px 0;
  text-align: left;
  color: #6d6d67;
  font-size: 18px;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 600;
  .views-row{
    padding: 0 15px;
    text-align: center;
    span.views-label{
      display: inline-block;
    }
    div.field-content{
      display: inline-block;
    }
  }
  .contextual-links{
    li{
      a{
        font-weight: 400;
        text-transform: none;
        &:hover{
          color: #ffad10;
        }
      }
    }
  }
}

.addtoany_list {
  display: block;
  line-height: 16px;
  padding: 15px 0 0 0;
  font-size: 0;
  a{
    padding: 0;
    display: inline-block;
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
}

#view-field-agent-name-table-column{
  a{
    color: #ffad10;
  }
}

#block-userhelpblock{
  padding: 20px 15px;
  background-color: #fff;
  margin: 20px 0;
  text-align: center;
  color: #6d6d67;
  h2{
    margin: 0 0 20px 0;
  }
  .user-help-block{
    a{
      display: inline-block;
      margin: 0 10px 0 0;
      color: #ffad10;
      border-bottom: 1px solid transparent;
      &:last-child{
        margin: 0;
      }
      &:hover{
        border-bottom: 1px solid #ffad10;
      }
    }
  }
}

#block-qarea-page-title{
  h1.js-quickedit-page-title{
    /*display: none;*/
    display: inline-block;
  }
}

#block-views-block-recent-tips-block-2{
  nav.pager {
    .pager__items.js-pager__items {
      a {
        color: #ffad10;
      }
    }
  }
}

body{
  &.path-recent-events{
    .live-events-wrapper{
      h2{
        color: #fff!important;
      }
    }
  }
  #block-qarea-content{
    &.block-system{
      &.block-system-main-block{
        article{
          &.article_agent_percents_wrap{
            > div{
              text-align: center;
              background: #fff;
              padding: 20px 25px;
              text-transform: uppercase;
              color: #6d6d67;
              font-size: 18px;
              font-family: sans-serif;
              font-weight: 600;
              margin: 0 0 20px 0;
              &:last-child{
                margin: 0;
              }
              > div{
                &:first-child{}
                &:last-child{}
              }
            }
          }
          > div{
            padding-left: 25px;
            padding-right: 25px;
            &.agent-percents{
              background: #fff;
              padding: 20px 25px;
              text-transform: uppercase;
              color: #6d6d67;
              font-size: 18px;
              font-family: sans-serif;
              font-weight: 600;
              text-align: center;
            }
            .contextual-links{
              li{
                a{
                  color: #6d6d67;
                }
              }
            }
          }
        }
        div{
          p{
            color: #6d6d67;
            font-family: Ubuntu,sans-serif;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.5;
          }
          a{
            color: #ffad10;
          }
        }
        form.contact-message-feedback-form{
          background-color: #fff;
          max-width: 790px;
          margin: 0 auto;
          width: 100%;
          padding: 40px 25px;
          @include respond-to(ipad){
            padding: 100px 25px;
          }
          > .form-item{
            overflow: hidden;
            padding: 10px 0;
            max-width: 540px;
            margin: 0 auto;
            width: 100%;
            label{
              color: #6d6d67;
              font-family: Lato,sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 1.5;
              float: left;
              width: 25%;
              &.option{
                color: #6d6d67;
                font-family: Lato,sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 1;
                float: left;
              }
            }
            input[type=text],
            input[type=email]{
              color: #6d6d67;
              font-family: Lato,sans-serif;
              font-weight: 600;
              font-size: 18px;
              width: 75%;
              text-transform: none;
              float: left;
              padding: 5px;
              border: none;
              border-bottom: 1px solid #9b9b8e;
              background: 0 0;
              box-shadow: none;
            }
            #edit-copy{
              margin: 0 10px 0 0;
              float: left;
            }
          }
          > .form-wrapper{
            padding: 10px 0;
            max-width: 540px;
            margin: 0 auto;
            width: 100%;
            .button{
              margin: 0 20px 0 0;
              &:last-child{
                margin: 0;
              }
            }
          }
          #edit-name{
            color: #6d6d67;
            font-family: Lato, sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.5;
            label{
              font-weight: 400;
              font-size: 14px;
              width: 25%;
              display: inline-block;
            }
          }
          #edit-mail{
            color: #6d6d67;
            font-family: Lato, sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.5;
            label{
              font-weight: 400;
              font-size: 14px;
              width: 25%;
              display: inline-block;
            }
            &.error{
              border-bottom: 1px solid red;
            }
          }
          #edit-subject-wrapper{
            .form-item{
              overflow: hidden;
              label{
                color: #6d6d67;
                font-family: Lato, sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                float: left;
                width: 25%;
              }
              input{
                color: #6d6d67;
                font-family: Lato, sans-serif;
                font-weight: 600;
                font-size: 18px;
                width: 75%;
                text-transform: none;
                float: left;
                padding: 5px;
                border: none;
                border-bottom: 1px solid #9b9b8e;
                background: 0 0;
                box-shadow: none;
              }
            }
          }
          #edit-message-wrapper{
            .form-item{
              overflow: hidden;
              label{
                color: #6d6d67;
                font-family: Lato, sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                float: left;
                width: 25%;
              }
              div{
                textarea{
                  color: #6d6d67;
                  font-family: Lato, sans-serif;
                  font-weight: 600;
                  font-size: 18px;
                  width: 75%;
                  max-width: 75%;
                  max-height: 200px;
                  text-transform: none;
                  float: left;
                  border: 1px solid #9b9b8e;
                  background: 0 0;
                  box-shadow: none;
                  margin: 0;
                  padding: 10px;
                  &:focus{
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#block-qarea-local-tasks{
  margin: 0;
  padding: 20px 15px;
  float: none;
  &.block-local-tasks-block{
    .contextual-links{
      li{
        display: block;
        a{
          border: none;
          background-color: #fff;
          color: #333;
          display: block;
          font-family: sans-serif;
          font-size: small;
          line-height: 0.8em;
          margin: 0.25em 0;
          padding: 0.4em 0.6em;
        }
      }
    }
    > ul{
      border-bottom: 1px solid #a7a7a4;
      > li{
        > a{
          color: #ffad10;
          border: 1px solid #a7a7a4;
          border-bottom: none;
          padding: 5px 15px;
        }
      }
    }
  }
}
form.node-favorite-clubs-form{
  #edit-field-club-wrapper{
    max-width: 540px;
    width: 100%;
    margin: 0 auto 5px;
    .form-item{
      overflow: hidden;
      label{
        line-height: 1;
        width: 30%;
        max-width: 160px;
        padding: 15px 15px 0 0;
        font-family: Lato,sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        color: #6d6d67;
        float: left;
        background: 0 0;
      }
      input[type=text]{
        width: 70%;
        max-width: 350px;
        margin: 0 15px;
        font-family: Lato,sans-serif;
        font-size: 18px;
        font-weight: 700;
        text-transform: lowercase;
        color: #6d6d67;
        float: left;
        padding: 10px;
        border: none;
        border-bottom: 1px solid #a7a7a4;
        background: 0 0;
        box-shadow: none;
      }
    }
  }
  #edit-field-club-id-wrapper{
    max-width: 540px;
    width: 100%;
    margin: 0 auto 20px;
    .form-item{
      //overflow: hidden;
      &:before, &:after{
        content: '';
        display: table;
      }
      &:after{
        clear: both;
      }
      label{
        line-height: 1;
        width: 30%;
        max-width: 160px;
        padding: 15px 15px 0 0;
        font-family: Lato,sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        color: #6d6d67;
        float: left;
        background: 0 0;
      }
      input[type=number]{
        width: 70%;
        max-width: 350px;
        font-family: Lato,sans-serif;
        font-size: 18px;
        font-weight: 700;
        text-transform: lowercase;
        color: #6d6d67;
        float: left;
        padding: 10px;
        border: none;
        border-bottom: 1px solid #a7a7a4;
        background: 0 0;
        box-shadow: none;
        margin: 0 15px 10px;
      }
      #edit-field-club-id-0-value--description{
        font-size: 12px;
        clear: both;
        float: none;
      }
    }
  }
  #edit-field-sport-type-wrapper{
    max-width: 540px;
    margin: 0 auto 20px;
    width: 100%;
    .form-item{
      overflow: hidden;
      label{
        line-height: 1;
        width: 30%;
        max-width: 160px;
        padding: 15px 15px 0 0;
        font-family: Lato,sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        color: #6d6d67;
        float: left;
        background: 0 0;
      }
      select{
        width: 70%;
        max-width: 350px;
        font-family: Lato,sans-serif;
        font-size: 18px;
        font-weight: 700;
        text-transform: lowercase;
        color: #6d6d67;
        float: left;
        padding: 0;
        border: none;
        border-bottom: 1px solid #a7a7a4;
        background: 0 0;
        box-shadow: none;
        margin: 0 15px 10px;
        border-radius: 0;
      }
    }
  }
  > .js-form-type-vertical-tabs{
    max-width: 540px;
    margin: 0 auto;
    width: 100%;
    label{}
    .vertical-tabs{
      ul.vertical-tabs__menu{
        .vertical-tabs__menu-item{
          a{
            strong{}
            span{}
          }
        }
      }
      .vertical-tabs__panes{
        > details{
          textarea{
            max-width: 100%;
          }
        }
      }
    }
  }
  #edit-actions{
    max-width: 540px;
    margin: 40px auto 0;
    width: 100%;
    float: none;
    clear: both;
    display: block;
    overflow: hidden;
    text-align: center;
    input[type=submit]{}
    .dropbutton-wrapper{
      padding: 0!important;
      .dropbutton-widget{
        padding: 0!important;
        text-align: left;
        ul{
          li{
            margin: 0 10px 10px 0;
            font-size: 14px;
          }
        }
      }
    }
  }
  .captcha{
    max-width: 540px;
    width: 100%;
    margin: 0 auto 20px;
    img{}
    .form-item{
      position: relative;
      padding: 0 15px 10px 0;
      &:before, &:after{
        content: '';
        display: table;
      }
      &:after{
        clear: both;
      }
      label{
        width: 30%;
        float: left;
      }
      input{
        float: left;
        width: 70%;
        font-family: Lato,sans-serif;
        font-weight: 600;
        font-size: 18px;
        text-transform: none;
        padding: 5px;
        border: none;
        border-bottom: 1px solid #9b9b8e;
        background: 0 0;
        box-shadow: none;
        margin-bottom: 0.75em;
      }
      .description{
        position: absolute;
        bottom: 0;
        right: 15px;
        float: none;
      }
    }
  }
}
form.node-favorite-clubs-delete-form{
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  color: #6d6d67;
  max-width: 540px;
  margin: 0 auto;
  width: 100%;
  #edit-actions{
    margin: 40px 0 0 0;
    #edit-submit{
      margin: 0 20px 0 0;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////

form.node-bookmakers-edit-form{
  padding: 40px 25px;
  background-color: #fff;
  @include respond-to(ipad){
    padding: 100px 25px;
  }
  > div{
    .form-item{
      label{
        float: left;
      }
      input[type=text]{
        float: left;
      }
      &:before, &:after{
        content: '';
        display: table;
      }
      &:after{
        clear: both;
      }
    }
  }
  .field--name-title{
    .form-item{
      label{}
      input[type=text]{}
    }
  }
  .field--name-field-site-url{}
  .field--name-field-login{}
  .field--name-field-password{}
  .field--name-field-to-user{}
  .form-actions{
    #edit-delete{
      color: #fff;
      &:hover{
        color: #ffad10;
      }
    }
  }
}
form#user-login-form{
  background: #fff;
  padding: 20px 0;
  .form-item{
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 920px;
    padding: 0 15px 20px;
    &.form-item-name, &.form-item-pass{
      input[type=text]{
        color: #6d6d67;
        font-family: Lato,sans-serif;
        font-weight: 600;
        font-size: 18px;
        text-transform: none;
        padding: 5px;
        border: none;
        border-bottom: 1px solid #9b9b8e;
        background: 0 0;
        box-shadow: none;
        width: 100%;
        display: block;
        @include respond-to(ipad){
          float: left;
          width: 70%;
        }
      }
      .description{
        position: absolute;
        top: 100%;
        right: 15px;
        float: none;
        margin: -27px 0 0 15px;
      }
    }
    &.form-item-pass{
      label{
        &.form-required{
          margin: 10px 0 0 0;
        }
      }
      #edit-pass{
        color: #6d6d67;
        font-family: Lato,sans-serif;
        font-weight: 600;
        font-size: 18px;
        text-transform: none;
        padding: 5px;
        border: none;
        border-bottom: 1px solid #9b9b8e;
        background: 0 0;
        box-shadow: none;
        width: 100%;
        display: block;
        @include respond-to(ipad){
          float: left;
          width: 70%;
        }
      }
    }
    &:before, &:after{
      content: '';
      display: table;
    }
    &:after{
      clear: both;
    }
    label{
      font-family: Lato,sans-serif;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      color: #6d6d67;
      width: 100%;
      display: block;
      @include respond-to(ipad){
        float: left;
        width: 30%;
      }
    }
  }
  #edit-actions{
    width: 100%;
    margin: 10px auto 0;
    max-width: 920px;
    padding: 0 15px;
    text-align: center;
  }
  .captcha{
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 920px;
    padding: 0 15px;
    img{}
    .form-item{
      max-width: 100%;
      padding: 0 0 20px 0;
      label{}
      input{
        float: left;
        width: 70%;
        font-family: Lato,sans-serif;
        font-weight: 600;
        font-size: 18px;
        text-transform: none;
        padding: 5px;
        border: none;
        border-bottom: 1px solid #9b9b8e;
        background: 0 0;
        box-shadow: none;
        margin-bottom: 0.75em;
      }
      .description{
        position: absolute;
        top: 100%;
        right: 0;
        float: none;
        margin: -27px 0 0 0;
      }
    }
  }
}
///// styles from js classes

.agent_percents{
  background-color: #fff;
  text-align: center;
  color: #6d6d67;
  font-size: 18px;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 600;
}
.main_table_wrapper{
  overflow-x: scroll;
  @include respond-to(ipad){
    overflow-x: visible;
  }
  .main_table, .matches-stats{
    width: 800px;
    @include respond-to(ipad){
      width: 100%;
      margin: 0 auto;
      table-layout: fixed;
    }
    thead{
      background-color: #ffad10;
      tr{
        th{
          vertical-align: middle;
          font-size: 16px;
          color: #fff;
          font-weight: 900;
          font-family: Lato, sans-serif;
          text-transform: uppercase;
          a{
            color: #fff!important;
          }
        }
      }
    }
    tr{
      th{
        vertical-align: middle;
        font-size: 16px;
        color: #fff;
        font-weight: 900;
        font-family: Lato, sans-serif;
        text-transform: uppercase;
        a{
          color: #fff!important;
        }
      }
      td{
        font-size: 18px;
        color: #363532;
        font-family: Lato, sans-serif;
        font-weight: 400;
        word-wrap: break-word;
        word-break: break-all;
        a{
          color: #ffad10;
          font-weight: 700;
          font-size: 18px;
          border-bottom: 1px solid transparent;
          &:hover{
            border-bottom: 1px solid #ffad10;
          }
        }
        &.views-field-field-email{
          text-align: left;
          font-size: 12px;
          padding: 0;
        }
        &.views-field-created{
          word-break: normal;
        }
      }
    }
  }
  .matches-stats{
    tr{
      &:first-child{
        background-color: #ffad10;
      }
      th, td{
        padding: 15px 10px;
      }
      td{
        border-bottom: 1px solid #ffad10;
      }
    }
  }
  + footer{
    margin-top: 20px;
    font-size: 14px;
    color: #6d6d67;
    text-transform: uppercase;
    font-family: sans-serif;
  }
}
.contextual-links{
  li{
    a{
      font-weight: 400;
      text-transform: none;
      &:hover{
        color: #ffad10;
      }
    }
  }
}

.close-message{
  position:absolute;
  right: 5px;
  top: 5px;
  color: #fff;
  display: inline-block;
  cursor: pointer;
  font-size: 24px;
  cursor: pointer;
  &:hover{
    opacity: 0.7;
  }
}
.messages--error{
  /*display: none;*/
  position: relative;
  margin: 0;
  padding: 20px 25px;
  > div{
    font-size: 16px;
    color: #fff;
    a{
      font-size: 16px;
      text-decoration: underline;
      color: #fff;
    }
  }
  .messages__list{
    li{
      font-size: 16px;
      color: #fff;
      margin: 0 0 10px 0;
      &:last-child{
        margin: 0;
      }
    }
  }
}
.recovery-passs-block{
  background-color: #fff;
  text-align: center;
  border-top: 1px solid #ffad10;
  width: 100%;
  padding: 20px 25px;
  @include respond-to(ipad){
    //padding: 100px 25px;
  }
  > h2{
    font-size: 24px;
    color: #6d6d67;
    padding: 0;
    margin: 0 0 20px 0;
  }
  > div{
    p{
      margin: 0 0 20px 0;
      font-size: 16px;
      color: #6d6d67;
      a{
        display: inline-block;
        color: #ffad10;
        border: 1px solid #ffad10;
        border-bottom: 1px solid #ffad10!important;
        padding: 12px 40px;
        text-align: center;
        font-size: 18px;
        font-family: Ubuntu,sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        &:hover{
          background-color: #ffad10;
          color: #fff!important;
        }
      }
      &:last-child{
        margin: 0;
      }
    }
  }
}
form.user-pass{
  background: #fff;
  padding: 40px 25px;
  @include respond-to(ipad){
    padding: 100px 25px;
  }
  p{
    font-size: 18px;
    font-weight: 400;
  }
}
#block-qarea-content{
  .favorite-club-view{
    .views-field-field-club-name{
      .field-content{
        text-transform: uppercase;
        font-family: Lato,sans-serif;
        font-weight: 900;
        font-size: 22px;
        text-align: center;
        margin: 0 0 40px;
      }
    }
  }
  form.user-form{}
  form.user-pass{
    background: #fff;
    padding: 20px 25px;
    @include respond-to(ipad){
      //padding: 100px 25px;
    }
    p{
      font-size: 18px!important;
      color: #6d6d67!important;
      font-weight: 500!important;
      margin: 0 0 20px 0!important;
      em{
        text-decoration: underline;
        font-weight: 700!important;
      }
    }
    .form-item{
      .form-required{
        margin: 0 0 10px 0;
        display: block;
      }
    }
  }
  form.user-pass-reset{
    p{
      color: #fff!important;
      margin: 0 0 5px 0;
      font-size: 14px!important;
      font-weight: 400!important;
    }
    #edit-actions{
      margin: 15px 0 0 0;
    }
  }
  form.user-register-form, form.user-form{
    #edit-account{
      #edit-mail{
        &.error{
          border-bottom: 2px solid #cd4533;
        }
      }
    }
    .field--type-image{
      .image-widget{
        margin: 10px 0 0 0;
        img{
          float: left;
          margin: 0 10px 0 0;
        }
        .js-form-type-textfield{

        }
        input[type=submit]{
          &.button{
            float: right;
          }
        }
      }
    }
  }
}
ul.payment-logos{
  width: 100%;
  line-height: 0;
  font-size: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin: 0 0 25px;
  margin: 0;
  li{
    display: flex;
    align-items: center;
    span{
      display: block;
    }
    .bankwire-logo{
      width: 44px;
      height: 42px;
      background: url('../images/Bank-Wire-Logo.jpg') no-repeat;
    }
    .bitcoin-logo{
      width: 42px;
      height: 42px;
      background: url('../images/bitcoin.png') no-repeat;
    }
    .skrill-logo{
      width: 42px;
      height: 42px;
      background: url('../images/ico-skrill.png') no-repeat;
    }
    .pm-logo{
      width: 42px;
      height: 42px;
      background: url('../images/pm.png') no-repeat;
    }
    .webmoney-logo{
      width: 43px;
      height: 43px;
      background: url('../images/mon.png') no-repeat;
    }
    .ae-logo{
      width: 42px;
      height: 42px;
      background: url('../images/american_ex.png') no-repeat;
    }
  }
}
/* rtl */

.lang_fa{
  .user-form{
    /*strong{
      .field--type-telephone{
        max-width: 410px;
        margin: 0 auto;
        width: 100%;
        padding: 10px 0;
        color: #6d6d67;
        display: block;
      }
    }*/
    .field--type-telephone, .field--type-file, .form-actions{
      max-width: 410px;
      margin: 0 auto;
      width: 100%;
      padding: 10px 0;
      color: #6d6d67;
      display: block;
    }
    #edit-actions{
      input{
        &:first-child{
          margin: 0!important;
        }
      }
    }
  }
  &.path-odds-calculator{
    #block-qarea-content{
      .inputTable{
        form{
          table{
            tr{
              td:first-child{
                width: 25% !important;
              }
            }
          }
        }
      }
    }
  }
  form#user-login-form{
    .form-item{
      label,
      input{
        float: right !important;
      }
    }
  }
  .close-message{
    left: 5px;
    right: auto;
  }
  ul, ol{
    li{
      a{
        font-family: 'BYekan', 'Yekan', sans-serif!important;
      }
    }
  }
  .addtoany_list {
    a{
      margin-left: 10px;
      margin-right: 0;
    }
  }
  .messages{
    .close-message{
      left: 5px;
      right: auto;
    }
  }
  #header{
    #header-wrapper{
      #block-qarea-account-menu--2{
        >ul{
          >li.user_account_item{
            a.user_account_ref{
              text-align: center;
              &:before{
                //content: 'حساب من';
                content: 'پنل کاربری';
              }
            }
          }
        }
      }
    }
  }
}
.lang_fa{
  .bottom-region{
    #bottom-region-wrapper{
      .social-links-block{
        div{
          a{
            i{
              font-family: FontAwesome !important;
            }
          }
        }
      }
    }
  }
  form#user-login-form{
    #edit-actions{
      text-align: right;
    }
  }
  #block-qarea-content{
    form.node-withdraw-request-form,
    form.node-deposit-request-form,
    form.node-bookmakers-edit-form{
      .captcha,
      .form-wrapper{
        .form-item{
          input,
          label{
            float: right !important;
          }
        }
      }
    }
  }
}
body.lang_fa{
  font-family: 'BYekan', 'Yekan', sans-serif!important;
  *{
    font-family: 'BYekan', 'Yekan', sans-serif!important;
  }
  .top-region{
    ul{
      li{
        a{
          font-family: 'BYekan', 'Yekan', sans-serif!important;
        }
      }
    }
  }
  #header{
    ul{
      li{
        a{
          font-family: 'BYekan', 'Yekan', sans-serif!important;
        }
      }
    }
  }
  p{
    font-family: 'BYekan', 'Yekan', sans-serif!important;
  }
  a{
    font-family: 'BYekan', 'Yekan', sans-serif!important;
  }
  span{
    font-family: 'BYekan', 'Yekan', sans-serif!important;
  }
  td{
    font-family: 'BYekan', 'Yekan', sans-serif!important;
  }
  ul, ol{
    li{
      a{
        font-family: 'BYekan', 'Yekan', sans-serif!important;
      }
    }
  }
  .addtoany_list {
    a{
      margin-left: 10px;
    }
  }
  .bottom-region{
    #bottom-region-wrapper{
      #block-sociallinksblock{
        display: block;
        margin: 0 auto;
      }
    }
  }
  #header #header-wrapper #block-qarea-branding a img{
    margin-right: auto;
  }
  #header #header-wrapper #block-qarea-account-menu--2>ul>li{
    margin: 0;
  }
  .live-events-wrapper{
    .event-filters{
      > div{
        text-align: right;
        h2{
          float: right;
          &:after{
            left: -12px;
            right: auto;
          }
        }
      }
    }
    .favorite-clubs-list-wrapper{
      text-align: right;
      h2{
        float: right;
        &:after{
          left: -12px;
          right: auto;
        }
      }
    }
    .top-countries-list-wrapper{
      text-align: right;
      h2{
        float: right;
        &:after{
          left: -12px;
          right: auto;
        }
      }
    }
    .favorite-period-wrapper{
      text-align: right;
      h2{
        float: right;
        &:after{
          left: -12px;
          right: auto;
        }
      }
    }
  }
  form{
    &.node-lottery-bid-form{
      #edit-actions{
        #edit-submit{}
      }
    }
  }
}
.lang_fa{
  form#user-login-form{
    #edit-actions{
      text-align: right;
    }
  }
}
form.user-form .form-item input.error {
  border-bottom: 1px solid red !important;
}
body.lang_fa .inputTable input,
body.lang_fa .copyright-block p,
body.lang_fa nav.pager ul.pager__items li a,
body.lang_fa .views-element-container.block-views-blocksafebet-banners-block-1 span,
body.lang_fa .views-element-container.block-views-blocksafebet-banners-block-1 a,
body.lang_fa option,
body.lang_fa select,
body.lang_fa .captcha input,
body.lang_fa .live-event-table td small,
body.lang_fa .agent-subuser-form input,
body.lang_fa .node-ticket-form input,
body.lang_fa .user-form input,
body.lang_fa time,
body.lang_fa .node-favorite-clubs-form input,
body.lang_fa .main_table_wrapper td,
body.lang_fa .main_table_wrapper td a,
body.lang_fa .main_table_wrapper td div,
body.lang_fa .main_table_wrapper td span,
body.lang_fa .node-withdraw-request-form input,
body.lang_fa .node-deposit-request-form input,
body.lang_fa .block-system-main-block article div div:last-child,
body.lang_fa .block-system-main-block article div div:last-child a,
body.lang_fa .user-profile-email a,
body.lang_fa .front-page-middle-link span,
body.lang_fa .event_date .wrap_timer div,
body.lang_fa .user-register-form input,
body.lang_fa .user-login-form input,
body.lang_fa .user-pass input[type=text],
body.lang_fa .main-slideshow .item-list ul li.slide a .slider_head_title{
  font-family: Lato,sans-serif !important;
}
body.lang_fa.path-user #block-qarea-content form.user-form>div.js-form-type-checkbox {
  position: initial;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: none;
  margin-top: 20px;
}
body.lang_fa.path-user #block-qarea-content form.user-form>div.js-form-type-checkbox label.option:after {
  right: -20px;
}
body.lang_fa.path-user #block-qarea-content form.user-form>div.js-form-type-checkbox input{
  margin: 0 0 0 5px;
  float: right;
}
.user-profile-email .user-profile-email{
  padding: 0;
}
/*#block-qarea-content div > div {
  text-align: center;
}*/
body.lang_fa #block-agentsubusersblock.agent-subuser-form form#agent-subuser-form .captcha .form-item label{
  float: none;
}
body.lang_fa.path-user #block-qarea-content form.user-form > div.js-form-type-checkbox.js-form-item-legal-terms-and-conditions{
  bottom: 133px;
}
.empty-url {
  pointer-events: none;
  cursor: default;
}
.field--name-user-picture .js-form-item,
.field--name-field-user-id-file .js-form-item{
  text-align: left !important;
}
.field--name-user-picture .js-form-item label,
.field--name-field-user-id-file .js-form-item label{
  text-align: center;
}
details#edit-language {
  margin: auto;
  max-width: 410px;
}
body.lang_fa .field--name-user-picture .js-form-item label,
body.lang_fa .field--name-field-user-id-file .js-form-item label{
  float: right;
}
.path-live-events #block-qarea-content div > div,
.path-recent-events #block-qarea-content div > div {
  text-align: left;
}
body.lang_fa.path-live-events #block-qarea-content div > div,
body.lang_fa.path-recent-events #block-qarea-content div > div{
  text-align: right;
}
.path-live-events .event-filters .show-all,
.live-events-wrapper .event-filters .show-all {
  text-align: center !important;
}
.live-events-wrapper table.live-event-table tr.competition td,
.live-events-wrapper table.live-event-table tr td.status span.result,
.live-events-wrapper table.live-event-table tr td.status span.result b{
  font-family: Lato,sans-serif !important;
}
body.path-best-tipsters-current-month #block-qarea-content .views-element-container,
body.path-best-tipsters-previous-month #block-qarea-content .views-element-container,
body.path-best-tipsters-two-month-ago #block-qarea-content .views-element-container,
body.path-best-tipsters #block-qarea-content .views-element-container{
  padding: 25px;
}
body.path-user details#edit-timezone label{
  padding:0 10px;
}
body.path-user details#edit-timezone select{
  font-size: 14px;
  min-width: 120px;
}
body.path-user #block-qarea-content form.user-form .form-item .description{
  text-align: left;
  padding: 0 10px;
}
body.lang_fa.path-user #block-qarea-content form.user-form .form-item .description{
  text-align: right;
}
body.path-user #block-qarea-content .user-form .form-actions{
  text-align: center;
}
body.lang_fa .main-slideshow .item-list ul li.slide a .slider_head .team_ratio.team_ratio_clone .team_ratio_item{
  font-family: Lato,sans-serif !important;
}
body.lang_fa .main-slideshow .item-list ul li.slide a .slider_head .team_ratio.team_ratio_clone .team_ratio_item{
  font-family: Lato,sans-serif !important;
}
body.path-user details#edit-timezone {
  margin: auto;
  max-width: 415px;
}
#edit-field-payment-method-bank-wire + label {
  min-width: 80px;
}
body.lang_fa #edit-field-payment-method input + label{
  min-width: 72px;
}
body.lang_fa.path-node #block-qarea-content form.node-deposit-request-form .form-wrapper .fieldgroup .fieldset-wrapper .webform-options-display-one-column .form-item{
  float: left;
}
input.close_ticket {
  background-color: #222220;
  margin-right: 30px;
}
body.path-user.lang_fa #block-qarea-content form.user-form .form-item input[type=tel] {
  text-align: right;
  direction: ltr;
}
body.lang_fa .block-system-main-block article div div:last-child a{
  direction: ltr;
  display: inline-block;
}
body.lang_fa .middle_links_wrapper>div>div.page-middle-links .views-row .wrap_timer{
  direction: ltr;
}
.role--agent #block-qarea-content .user-form .form-actions #edit-delete{
  display: none;
}
body.lang_fa .main-slideshow .item-list ul li.slide a .slider_head .team_ratio.team_ratio_clone{
  direction: ltr;
}
body.lang_fa .block-system-main-block article div div h3 a{
  direction: rtl !important;
}
body.lang_fa .block-system-main-block article div div:last-child ul li a {
  direction: rtl;
  font-family: Lato,sans-serif !important;
}
body.lang_fa .block-system-main-block article div h3 a,
body.lang_fa .block-system-main-block article div ul li a {
  font-family: Lato,sans-serif !important;
}
body.lang_fa #block-usermenublock + #block-qarea-page-title h1{
  font-family: Lato,sans-serif !important;
}
body.lang_fa #block-usermenublock .user-account-wrapper .user-account-image,
body.lang_fa #block-usermenublock .user-account-wrapper .user-agent-menu .menu-set{
  float: right;
}
body.lang_fa #block-usermenublock .user-account-wrapper .user-agent-menu .menu-set h4,
body.lang_fa #block-usermenublock .user-account-wrapper .user-agent-menu .menu-set{
  text-align: right;
}
.main_table_wrapper .views-field-field-agent-points-amount{
  direction: ltr;
}
body.lang_fa.path-best-tipsters-current-month #block-qarea-content .views-element-container div header>a:last-child {
  margin: 0 20px 0 0;
}
body.lang_fa #block-qarea-content.block-system.block-system-main-block article.article_agent_percents_wrap > div,
body.lang_fa .live-events-wrapper table.live-event-table tr td{
  direction: ltr;
}
#block-views-block-recent-tips-block-2 nav.pager .pager__items.js-pager__items a{
  color: #ffad10;
}
@media (min-width: 700px){
  .main-slideshow .item-list ul li.slide a .slider_head_date time {
    font-size: 20px !important;
  }
  .main-slideshow .item-list ul li.slide a .slider_head_title {
    font-size: 45px !important;
  }
  .main-slideshow .item-list ul li.slide a .slider_head_matches > div img {
    width: auto !important;
  }
  .main-slideshow .item-list ul li.slide a .slider_head_matches .vs {
    font-size: 48px !important;
  }
  .main-slideshow .item-list ul li.slide a .slider_head .team_ratio.team_ratio_clone .team_ratio_item {
    padding: 10px 30px 0 !important;
    font-size: 30px !important;
  }
}
@media (max-width: 700px){
  .main-slideshow .item-list ul li.slide a .slider_head .team_ratio.team_ratio_clone .team_ratio_item {
    padding: 0 7px!important;
    font-size: 18px !important;
  }
  .main-slideshow .item-list ul.slick-dots{
    margin: 0 -50% 0 0 !important;
    bottom: 0!important;
  }
  .main-slideshow .item-list ul li.slide a .slider_head .team_ratio.team_ratio_clone{
    padding: 10px 0 0 0!important;
  }
}

