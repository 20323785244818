body{
  &.path-node{
    #block-qarea-content{
      background-color: #fff;
      max-width: 1170px;
      margin: 0 auto;
      width: 100%;
      padding: 0;
      form.node-deposit-request-form, form.node-withdraw-request-form, form.node-bookmakers-edit-form{
        background: transparent;
        max-width: 590px;
        margin: 0 auto;
        width: 100%;
        padding: 20px 25px;
        @include respond-to(ipad){
          padding: 20px 25px 20px;
        }
        #edit-delete{
          color: #fff;
        }
        .form-wrapper{
          .form-item{
            overflow: hidden;
            width: 100%;
            margin: 0 0 5px 0;
            label{
              line-height: 1;
              font-family: Lato, sans-serif;
              font-size: 14px;
              font-weight: 400;
              text-transform: uppercase;
              color: #6d6d67;
              background: none;
              width: 100%;
              display: block;
              padding: 0;
              @include respond-to(ipad){
                width: 30%;
                padding: 15px 15px 0 0;
                float: left;
                max-width: 160px;
              }
            }
            input[type=text], input[type=email], input[type=number]{
              font-family: Lato, sans-serif;
              font-size: 18px;
              font-weight: 700;
              text-transform: lowercase;
              color: #6d6d67;
              padding: 10px;
              border: none;
              border-bottom: 1px solid #a7a7a4;
              background: none;
              box-shadow: none;
              width: 100%;
              @include respond-to(ipad){
                width: 70%;
                float: left;
                margin: 0 15px;
                max-width: 350px;
              }
            }
          }
          .description{
            opacity: 1;
            //visibility: hidden;
            //height: 0;
            display: block;
            font-size: 12px;
            float: none;
            clear: both;
            padding: 5px 0;
          }
          .field-prefix{
            font-size: 0;
            opacity: 0;
            visibility: hidden;
            height: 0;
          }
          .fieldgroup{
            overflow: hidden;
            legend{
              width: 100%;
              display: block;
              font-family: Lato, sans-serif;
              font-size: 14px;
              font-weight: 400;
              text-transform: uppercase;
              color: #6d6d67;
              background: none;
              margin: 0 0 20px 0;
              @include respond-to(ipad){
                width: 30%;
                max-width: 160px;
                margin: 0;
                float: left;
              }
              span{
                display: block;
              }
            }
            .fieldset-wrapper{
              padding: 0;
              margin: 0;
              font-family: Lato, sans-serif;
              font-size: 18px;
              font-weight: 700;
              text-transform: lowercase;
              color: #6d6d67;
              border: none;
              background: none;
              box-shadow: none;
              @include respond-to(ipad){
                float: left;
                width: 70%;
                max-width: 370px;
              }
              .webform-options-display-one-column{
                overflow: hidden;
                .form-item{
                  width: 100%;
                  margin: 0 0 10px 0;
                  font-size: 0;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  @include respond-to(ipad){
                    float: left;
                    padding: 0 15px 10px;
                    width: auto;
                    margin: 0;
                  }
                  input[type=radio]{
                    //display:none;
                    display: inline-block;
                    float: left;
                    margin: 0 5px 0 0;
                    /*+ label{
                      span{
                        width:19px;
                        height:19px;
                        margin:-1px 4px 0 0;
                        vertical-align:middle;
                        cursor:pointer;
                        -moz-border-radius:  50%;
                        border-radius:  50%;
                      }
                    }
                    &:checked{
                      + label{
                        span{
                          background-color:#fff;
                          position: relative;
                          &:after{
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 12px;
                            height: 12px;
                            border-radius: 100%;
                            background: red;
                            margin: -6px 0 0 -6px;
                          }
                        }
                      }
                    }*/
                  }
                  label.option{
                    float: left;
                    font-family: Lato, sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: capitalize;
                    color: #6d6d67;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
        #edit-actions{
          text-align: center;
          font-size: 0;
          input[type=submit]{
            display: inline-block;
            margin-right: 20px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        .captcha{
          margin: 0 0 20px 0;
          img{}
          .form-item{
            position: relative;
            padding: 0 15px 10px 0;
            &:before, &:after{
              content: '';
              display: table;
            }
            &:after{
              clear: both;
            }
            label{
              width: 30%;
              float: left;
            }
            input{
              float: left;
              width: 70%;
              font-family: Lato,sans-serif;
              font-weight: 600;
              font-size: 18px;
              text-transform: none;
              padding: 5px;
              border: none;
              border-bottom: 1px solid #9b9b8e;
              background: 0 0;
              box-shadow: none;
              margin-bottom: 0.75em;
            }
            .description{
              position: absolute;
              bottom: 0;
              right: 15px;
              float: none;
            }
          }
        }
        #edit-field-payment-method-wrapper{
          margin: 10px 0 0 0;
        }
      }
    }
  }
  &.lang_fa{
    #block-qarea-content{
      form.node-deposit-request-form, form.node-withdraw-request-form, form.node-bookmakers-edit-form{
        .form-wrapper{
          .fieldgroup{
            legend{
              @include respond-to(ipad){
                float: right;
              }
            }
          }
        }
      }
    }
  }
}
