#slide_menu {
  user-select: none;
  width: 100%;
  padding-right: 0;
  right: -100%;
  background: #151819;
  height: 100%;
  min-height: 100%;
  *zoom: 1;
  position: fixed;
  z-index: 999999999;
  top: 0;
  transition: all 200ms ease-in-out;
  @include respond-to(ipad){
    display: none;
  }
  .slideMenuContainer{
    height: 100%;
    display: block !important;
    min-height: 100%;
    width: 100%;
  }
  &.slided{
    right: 0;
  }
  /*.slide_menuClose {
    display: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 15px 25px;
    background: #151819;
    height: 60px;
    position: relative;
    z-index: 30;
    font-family: 'Lato', sans-serif;
    img {
      max-height: 100%;
    }
  }
  .slide_menuSearch {
    color: #fff;
    font-size: 1.6rem;
    font-family: 'Lato', Arial, sans-serif;
    line-height: 1.2;
    padding: 15px 50px 15px 15px;
    margin: 0;
    border: 1px solid #606263;
    border-radius: 3px;
    width: 100%;
    position: relative;
    background: transparent;
  }*/
  #block-topsitemenu--2{
    > ul {
      > li{
        user-select: none;
        position: relative;
        padding: 0 15px;
        border-bottom: 1px solid #313435;
         a{
          color: #fff;
          font-size: 18px;
          font-family: 'PT Sans', Arial, sans-serif;
          font-weight: 400;
          padding: 0 10px;
          line-height: 60px;
          display: inline-block;
          &:hover {
            color: #ff7d0f;
          }
          &.active {
            color: #ff7d0f;
          }
          &.is-active{
            color: #ff7d0f;
          }
        }
        ul{
          display: none;
          padding: 0 0 0 10px;
          li{
            //padding: 5px 25px;
            a{
              font-size: 14px;
              line-height: 30px;
              display: block;
              border-bottom: 1px solid #313435;
              //padding: 3px 0 3px 20px;
              padding: 0;
            }
            &:last-child{
              a{
                border-bottom: none;
              }
            }
          }
        }
        &.has_child_mobile{
          cursor: pointer;
          position: relative;
          &.open{
            &:after{
              content: ' - ';
              top: 5px;
              right: 30px;
            }
          }
          &:after{
            content: ' + ';
            display: inline-block;
            font-size: 38px;
            color: #fff;
            position: absolute;
            top: 10px;
            right: 25px;
          }
        }
      }
    }
  }
  .slideMenuClose{
    position: absolute;
    right: 18px;
    top: 15px;
    color: #ff7d0f;
    z-index: 12;
    cursor: pointer;
    font-size: 30px;
    background: url(/themes/qarea/images/multiply.png) center center no-repeat;
    width: 25px;
    height: 25px;
    img{
      width: 25px;
      display: block;
      opacity: 0;
    }
  }
  &:before, &:after{
    display: table;
    content: "";
    line-height: 0;
  }
  &:after{
    clear: both;
  }
}

.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.nano > .nano-content {
  position: absolute;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 50px;
}
.nano > .nano-content:focus {
  outline: thin dotted;
}
.nano > .nano-content::-webkit-scrollbar {
  visibility: hidden;
}
.nano > .nano-pane {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 10px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 50;
  /* Target only IE7 and IE8 with this hack */
  visibility: hidden\9;
  opacity: .01;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.nano > .nano-pane > .nano-slider {
  background: #9d9d9d;
  position: relative;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.nano .nano-pane {
  background: #5b5b5b;
  width: 4px;
  right: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
/* line 1470, view\index.less */
.nano .nano-slider {
  background: #111;
}
.nano:hover > .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
  /* Target only IE7 and IE8 with this hack */
  visibility: visible\9;
  opacity: 0.99;
}
.has-scrollbar > .nano-content::-webkit-scrollbar {
  visibility: visible;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: border-box;
}
body{
  &.lang_fa{
    #slide_menu{
      #block-topsitemenu--2{
        > ul{
          > li{
            &.has_child_mobile{
              &:after{
                left: 25px;
                right: auto;
              }
              &.open{
                &:after{
                  left: 30px;
                  right: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}
