@font-face {
  font-family: 'BYekan';
  src: url('../fonts/BYekan.woff') format('woff'),
  url('../fonts/BYekan.ttf') format('truetype'),
  url('../fonts/BYekan.svg#BYekan') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yekan';
  src: url('../fonts/Yekan.eot');
  src: url('../fonts/Yekan.woff2') format('woff2'),
  url('../fonts/Yekan.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

