.bookmaker_slider{
  padding: 20px 0;
  @include respond-to(ipad){
    padding-top: 20px;
    //padding-bottom: 120px;
  }
  .item-list{
    ul{
      li{
        a{
          display: block;
          img{
            width: auto;
            margin: 0 auto;
            max-height: 60px;
          }
        }
        img{
          width: auto;
          margin: 0 auto;
          max-height: 60px;
        }
      }
    }
    .slick-slider{
      padding: 0 50px;
      .slick-track{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .slick-arrow{
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        background-color: rgba(255, 255, 255, .7);
        background-position: 50% 50%;
        background-repeat: no-repeat!important;
        width: 40px;
        height: 40px;
        @include respond-to(large){
          width: 55px;
          height: 55px;
        }
        &.slick-prev{
          text-indent: -9999px;
          border: none;
          background-image: url('/themes/qarea/images/arrow_slider.png');
          left: 15px;
          @include respond-to(large){
            left: 0;
          }
        }
        &.slick-next{
          text-indent: -9999px;
          border: none;
          background-image: url('/themes/qarea/images/arrow_slide_2.png');
          right: -25px;
          @include respond-to(large){
            right: -50px;
          }
        }
        &:hover{
          background-color: rgba(255, 255, 255, .2);
        }
      }
    }
    .slick-dots{
      display: none!important;
    }
  }
}