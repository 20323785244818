.top-region {
  background-color: #ffad10;
  position: relative;
  z-index: 14;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  @include respond-to(ipad){
    display: block;
    padding: 0;
  }
  &:before, &:after{
    content: '';
    display: table;
  }
  &:after{
    clear: both;
  }
  #top-region-wrapper {
    /*position: absolute;
    right: 45px;
    font-size: 12px;
    padding: 0;
    //top: 20px;
    top: 50%;
    transform: translate(0, -50%);
    height: auto;
    max-width: 75em;
    margin: 0 auto;*/
    position: absolute;
    right: 45px;
    font-size: 12px;
    padding: 0;
    top: 20px;
    height: auto;
    max-width: 75em;
    margin: 0 auto;
    @include respond-to(ipad){
      transform: translate(0, 0);
      display: block;
      padding: 0 15px;
      height: 100%;
      position: relative;
      top: auto;
      right: auto;
    }
    .block-addtoany{
      float: left;
      width:30%;
      /*display: none;*/
    }
    &:before{
      content: '';
      display: table;
    }
    &:after{
      clear: both;
      content: "";
      display: table;
    }
    #block-topsitemenu {
      height: 100%;
      margin: 0 -15px;
      text-align: center;
      display: none;
      @include respond-to(ipad){
        display: block;
      }
      ul{
        font-size: 0;
        height: 100%;
        position: relative;
        li{
          display: inline-block;
          padding: 12px 15px;
          a {
            color: #5d4820;
            display: block;
            font-size: 15px;
            text-transform: uppercase;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 400;
            &:hover{
              color: #fff;
            }
          }
          > ul{
            @include transition(all 200ms ease-in-out);
            width: 200px;
            display: none;
            padding: 20px;
            position: absolute;
            z-index: 13;
            height: auto;
            background-color: #ffad10;
            > li{
              text-align: left;
              display: block;
              margin-bottom: 10px;
              padding: 0;
              &:last-child{
                margin-bottom: 0;
              }
              > a{}
            }
          }
          &:hover{
            > ul{
              display: block;
            }
          }
          &.has_child{
            > a{
              position: relative;
              &:after{
                content: '';
                @include transition(all 200ms ease-in-out);
                color: #5d4820;
                border: 4px solid transparent;
                border-top: 4px solid #5d4820;
                width: 8px;
                height: 4px;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: -10px;
              }
              &:hover{
                &:after{
                  border-top: 4px solid #fff;
                }
              }
            }
          }
        }
      }
      .contextual{
        .contextual-links{
          li{
            a{
              text-transform: none;
            }
          }
        }
      }
    }
    .language-switcher-language-url{
      //display: none;
      @include respond-to(ipad){
        display: block;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
      }
      .links{
        height: 36px;
        line-height: 36px;
        width: 60px;
        text-align: center;
        > li{
          position: relative;
          display: none;
          font-size: 12px;
          color: #5d4820;
          padding: 0 3px;
          > a.language-link{
            @include transition(all 200ms ease-in-out);
            color: #fff;
            font-size: 12px;
            @include respond-to(ipad){
              font-size: 15px;
            }
            &.is-active{
              color: #5d4820;
              &:after{
                content: '';
                color: #5d4820;
                border: 4px solid transparent;
                border-top: 4px solid #5d4820;
                width: 8px;
                height: 4px;
                position: absolute;
                left: 50%;
                transform: translate(-50%,0);
                bottom: 0;
                @include transition(all 200ms ease-in-out);
              }
              &:hover{
                color: #fff;
                &:after{
                  @include transition(all 200ms ease-in-out);
                  border-top: 4px solid #fff;
                }
              }
            }
            &:hover{
              color: #fff;
            }
          }
          &.is-active{
            display: block;
            a{
              color: #5d4820;
            }
          }
          &:hover{
            ul.clone_links{
              display: block;
            }
          }
          .clone_links{
            height: auto;
            display: none;
            position: absolute;
            bottom: -60px;
            background: #ffad10;
            left: 0;
            right: 0;
            width: 100%;
            > li{
              text-align: center;
              display: block;
              height: 30px;
              line-height: 30px;
              font-size: 0;
              &:hover{
                background-color: #fff;
                a{
                  color: #6d6d67;
                }
              }
              a{
                @include transition(all 200ms ease-in-out);
                font-size: 0;
                display: block;
                vertical-align: middle;
                &.language-link{
                  &:hover{
                    color: #6d6d67;
                  }
                }
                &:hover{
                  color: #6d6d67;
                }
                &:after{
                  content: attr(hreflang);
                  font-size: 14px;
                  text-transform: uppercase;
                  @include transition(all 200ms ease-in-out);
                  vertical-align: middle;
                }
                &[hreflang="fa"]:before{
                  content: "";
                  display: inline-block;
                  width: 20px;
                  height: 10px;
                  background: url("../images/flag-fa.jpg") center center no-repeat;
                  background-size: cover;
                  vertical-align: middle;
                  margin: 0 5px 0 0;
                }
                &[hreflang="en"]:before{
                  content: "";
                  display: inline-block;
                  width: 20px;
                  height: 10px;
                  background: url("../images/flag-en.jpg") center center no-repeat;
                  background-size: cover;
                  vertical-align: middle;
                  margin: 0 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
body{
  &.lang_fa{
    .top-region{
      direction: ltr;
      #top-region-wrapper{
        #block-topsitemenu{
          ul{
            li{
              > ul{
                > li{
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}
