@mixin respond-to($media) {
  @if $media == mobile_ipad {
    @media only screen and (min-width: $mobile_ipad) { @content; }
  }
  @else if $media == ipad {
    @media only screen and (min-width: $ipad){ @content; }
  }
  @else if $media == desktop {
    @media only screen and (min-width: $desktop) { @content; }
  }
  @else if $media == large {
    @media only screen and (min-width: $large) { @content; }
  }
}
@mixin transition($args) {
  transition: $args;
}