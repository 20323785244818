#block-agentsubusersblock{
  &.agent-subuser-form{
    background-color: #fff;
    padding: 40px 25px;
    h2{
      font-family: 'Ubuntu', sans-serif;
      font-size: 24px;
      color: #ffad10;
      font-weight: 400;
      margin: 0 0 20px 0;
      text-align: center;
      text-transform: uppercase;
    }
    form#agent-subuser-form{
      max-width: 410px;
      margin: 0 auto;
      width: 100%;
      .form-item{
        //overflow: hidden;
        font-size: 0;
        margin-bottom: 5px;
        label{
          color: #6d6d67;
          font-weight: 500;
          font-family: 'Ubuntu', sans-serif;
          font-size: 14px;
          //float: left;
          background: none;
          padding-right: 10px;
          text-transform: uppercase;
          display: block;
          width: 100%;
          @include respond-to(ipad){
            display: inline-block;
            width: 33%;
          }
        }
        input{
          //float: left;
          margin: 0;
          font-family: Lato,sans-serif;
          font-size: 22px;
          font-weight: 700;
          color: #6d6d67;
          text-transform: uppercase;
          float: none;
          padding: 5px;
          border: none;
          border-bottom: 1px solid #9b9b8e;
          background: 0 0;
          box-shadow: none;
          display: block;
          width: 100%;
          @include respond-to(ipad){
            display: inline-block;
            width: 67%;
          }
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
      .form-actions{
        text-align: right;
        .button{
          display: inline-block;
        }
      }
      .captcha{
        margin: 0 0 20px 0;
        img{}
        .form-item{
          position: relative;
          padding: 0 0 10px 0;
          &:before, &:after{
            content: '';
            display: table;
          }
          &:after{
            clear: both;
          }
          label{
            width: 33%;
            float: left;
          }
          input{
            float: left;
            width: 67%;
            font-family: Lato,sans-serif;
            font-weight: 600;
            font-size: 18px;
            text-transform: none;
            padding: 5px;
            border: none;
            border-bottom: 1px solid #9b9b8e;
            background: 0 0;
            box-shadow: none;
            margin-bottom: 0.75em;
          }
          .description{
            position: absolute;
            bottom: 0;
            right: 0;
            float: none;
          }
        }
      }
    }
  }
}