#block-usermenublock{
  padding: 20px 25px;
  width: 100%;
  background: #fff;
  @include respond-to(ipad){
    margin: 20px 0;
  }
  > h2{
    text-transform: uppercase;
    font-family: Lato,sans-serif;
    font-weight: 900;
    font-size: 22px;
    text-align: center;
    margin: 0 0 20px;
  }
  .user-account-wrapper{
    overflow: hidden;
    .user-account-image{
      @include respond-to(ipad){
        float: left;
        width: 15%;
      }
      .user_image{
        width: 82px;
        height: 82px;
        border-radius: 50%;
        margin: 0 auto 10px;
        img{
          display: block;
          max-width: 100%;
          border-radius: 50%;
        }
      }
      .user_name{
        font-size: 18px;
        color: #222220;
        font-weight: 700;
        font-family: Lato, sans-serif !important;
        text-transform: uppercase;
        margin: 0 0 10px 0;
        text-align: center;
      }
      .user_level{
        display: none;
        font-family: Ubuntu, sans-serif;
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
        color: #6d6d67;
        .level_letter{
          display: inline-block;
          font-weight: 700;
          padding: 0 0 0 20px;
        }
      }
    }
    .user-agent-menu{
      //overflow: hidden;
      background: transparent;
      //max-width: 840px;
      //margin: 0 auto;
      @include respond-to(ipad){
        float: right;
        width: 85%;
      }
      &:before, &:after{
        content: '';
        display: table;
      }
      &:after{
        clear: both;
      }
      .menu-set{
        padding: 0 25px;
        float: left;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
        @include respond-to(mobile_ipad){
          width: 50%;
        }
        @include respond-to(ipad){
          width: 25%;
          text-align: left;
          margin-bottom: 0;
        }
        h4{
          color: #a8a8a7;
          font-size: 18px;
          text-transform: uppercase;
          margin-bottom: 30px;
          letter-spacing: -0.025px;
          font-family: sans-serif;
          font-weight: 500;
          border-top: 1px solid #a8a8a7;
          border-bottom: 1px solid #a8a8a7;
          text-align: center;
          padding: 5px 0;
          @include respond-to(ipad){
            border: none;
            text-align: left;
            padding: 0;
          }
        }
        a{
          display: block;
          color: #6d6d67;
          font-size: 12px;
          text-transform: uppercase;
          font-family: sans-serif;
          font-weight: 700;
          margin-bottom: 25px;
          &.disabled{
            color: #a8a8a7;
          }
          &:last-child{
            margin-bottom: 0;
          }
          &:hover{
            color: #ffad10;
          }
        }
        &:last-child{
          margin-bottom: 0;
          min-height: 40px;
        }
      }
    }
  }
}
