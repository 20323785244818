.bottom-region {
  background-color: #2e2e2e;
  padding: 20px 0;
  color: white;
  #bottom-region-wrapper {
    padding: 0 15px;
    max-width: 820px;
    text-align: center;
    margin: 0 auto;
    .social-links-block{
      &.contextual-region{}
      div{
        &:before, &:after{
          content: '';
          display: table;
        }
        &:after{
          content: '';
          clear: both;
        }
        > a{
          display: inline-block;
          color: #fff;
          margin: 0;
          padding: 5px;
          @include respond-to(ipad){
            padding: 15px;
          }
          &:hover{
            color: #ffad10;
          }
          &:last-child{
            margin: 0;
          }
          i{
            font-size: 20px;
          }
        }
      }
    }
    .social-links-block, #block-qarea-footer{
      width: auto;
      display: block;
      text-align: center;
      @include respond-to(ipad){
        display: inline-block;
      }
      .contextual{
        ul.contextual-links{
          li{
            a{
              color: #6d6d67;
            }
          }
        }
      }
      > ul{
        overflow: hidden;
        display: inline-block;
        @include respond-to(ipad){
          display: block;
        }
        li {
          float: none;
          display: block;
          padding: 15px;
          &:last-child{
            margin: 0;
          }
          @include respond-to(ipad){
            float: left;
          }
          a{
            font-size: 12px;
            color: #fff;
            text-transform: uppercase;
            &:hover{
              color: #FEAB2E;
            }
          }
          &:last-child{
            margin: 0;
          }
        }
      }
    }
    .copyright-block{
      float: none;
      clear: both;
      width: 100%;
      color: #989898;
      text-align: center;
      font-size: 12px;
      padding: 20px 0 0 0;
      @include respond-to(ipad){
        padding: 40px 0 0 0;
      }
    }
  }
  #block-views-block-payment-methods-block-1{
    clear: both;
    float: none;
    .payment-methods{
      .item-list{
        ul{
          font-size: 0;
          li{
            display: inline-block;
            padding: 10px 15px;
            .views-field{
              .field-content{
                img{
                  width: 100%;
                  max-width: 42px;
                  max-height: 42px;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}