.main-slideshow{
  //box-shadow: -8px 200px 147px 126px rgba(0,0,0,0.83);
  //box-shadow: -8px 87px 126px 38px rgba(0,0,0,0.83);
  //height: 350px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  @include respond-to(ipad){
    height: auto;
  }
  .item-list{
    //padding-bottom: 40px;
    /*height: 100%;
    @include respond-to(ipad){
      height: 100%;
    }*/
    ul{
      //height: 100%;
      &.slick-dots{
        height: auto;
        /*height: auto;*/
      }
      li.slide{
        //height: 100%;
        a{
          display: block;
          position: relative;
          > img{
            display: block;
            width: 100%;
            height: 100%;
            max-height: 390px;
            @include respond-to(desktop){
              max-height: 560px;
            }
          }
          .slider_head{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            /*-moz-transform: translate(-50%, -50%);*/
            z-index: 2;
            text-align: center;
            max-width: 90%;
            width: 100%;
            &_date{
              text-align: center;
              margin: 0 0 5px 0;
              @include respond-to(ipad){
                margin: 80px 0 20px 0;
              }
              time{
                font-weight: 700;
                color: #fff;
                font-family: 'Squada One', sans-serif;
                text-transform: uppercase;
                font-size: 16px;
                letter-spacing: 1px;
                @include respond-to(ipad){
                  font-size: 24px;
                }
                @include respond-to(desktop){
                  font-size: 36px;
                }
              }
            }
            &_title{
              text-align: center;
              font-weight: 900;
              color: #fff;
              font-family: Lato, sans-serif;
              text-transform: uppercase;
              margin: 0 0 5px 0;
              font-size: 20px;
              @include respond-to(ipad){
                font-size: 28px;
              }
              @include respond-to(desktop){
                font-size: 60px;
                margin: 0 0 20px 0;
              }
            }
            &_matches{
              overflow: hidden;
              display: inline-block;
              > div{
                float: left;
                text-align: center;
                font-weight: 900;
                color: #fff;
                font-family: Lato, sans-serif;
                text-transform: uppercase;
                img{
                  display: block;
                  width: 40px;
                  height: auto;
                  @include respond-to(desktop){
                    width: auto;
                  }
                }
              }
              .first_team{}
              .vs{
                font-size: 16px;
                line-height: 1.8;
                padding: 0 10px;
                @include respond-to(ipad){
                  font-size: 20px;
                }
                @include respond-to(desktop){
                  //line-height: 1.8;
                  font-size: 48px;
                  padding: 0 20px;
                }
              }
              .second_team{}
              .team_ratio{
                display: none;
                /*float: none;
                clear: both;
                text-align: center;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                padding: 10px 0;
                font-size: 18px;
                text-transform: none;
                font-family: Lato, sans-serif !important;
                &_item{
                  display: flex;
                  text-transform: none;
                  font-family: Lato, sans-serif !important;
                  &:before{
                    padding: 0 10px 0 0;
                    content: 'test_word';
                    display: inline;
                  }
                  &:last-child{
                    &:before{
                      content: 'word';
                    }
                  }
                }*/
              }
            }
            .team_ratio.team_ratio_clone{
              display: flex;
              float: none;
              clear: both;
              text-align: center;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 20px 0 0;
              font-size: 18px;
              text-transform: none;
              .team_ratio_item{
                display: flex;
                padding: 10px 18px 0;
                font-size: 36px;
                font-weight: bold;
                text-transform: none;
                /*&:before{
                  padding: 0 10px 0 0;
                  content: 'test_word';
                  display: inline;
                }
                &:last-child{
                  &:before{
                    content: 'word';
                  }
                }*/
              }
            }
          }
        }
      }
    }
    ul.slick-dots{
      font-size: 0;
      text-align: center;
      display: block;
      position: absolute;
      left: 50%;
      bottom: 8px;
      transform: translate(-50%);
      li{
        display: inline-block;
        background-color: #1e1e1e;
        width: 15px;
        height: 15px;
        margin: 0 15px 0 0;
        border-radius: 100%;
        z-index: 12;
        &.slick-active{
          background-color: #ffad10;
        }
        &:last-child{
          margin: 0;
        }
        button{
          text-indent: -9999px;
          font-size: 0;
          background: none;
          border: 0;
        }
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
  .slick-slide{
    img{
      width: 100%;
    }
  }
}
body{
  &.lang_fa{
    .main-slideshow{
      .slick-dots{
        li{
          &:first-child{
            margin: 0;
          }
          &:last-child{
            margin: 0 15px 0 0;
          }
        }
      }
    }
  }
}

.slick-slider .slick-track, .slick-slider .slick-list {
  height: 100%!important;
  width: 100%;
}
