form.node-tip-form{
  max-width: 570px;
  margin: 0 auto;
  width: 100%;
  padding: 20px 15px 10px;
  .description{
    color: #6d6d67;
    @include respond-to(ipad){
      max-width: 328px;
      margin-left: auto;
      display: block;
    }
  }
  #edit-field-comment-tip-0-value--description{
    text-align: left;
    @include respond-to(ipad){
      max-width: 328px;
      margin-left: auto;
      display: block!important;
      text-align: left;
    }
  }
  #edit-field-tip-select-match--description{
    text-align: left;
    @include respond-to(ipad){
      max-width: 328px;
      margin-left: auto;
    }
  }
  .field--name-field-status{
    .form-item{
      padding: 1em 2.2em;
      &:before, &:after{
        content: '';
        display: table;
      }
      &:after{
        clear: both;
      }
    }
    label{
      width: 30%;
      float: left;
    }
    select{
      width: 70%;
      float: left;
      font-family: Lato,sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #6d6d67;
      margin-bottom: .75em;
    }
  }
  .accordion{
    counter-reset: list 0;
    h3.accordion-item{
      background: none;
      border: none;
      margin-bottom: 20px;
      color: #6d6d67;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Ubuntu', sans-serif;
      text-transform: uppercase;
      &.ui-accordion-header-active{
        margin-bottom: 0;
        background: none;
        span{
          &.ui-icon-triangle-1-s{
            color: #ffad10;
          }
        }
        a{
          color: #ffad10;
          &:before{
            color: #ffad10;
          }
        }
      }
      span{
        &.ui-icon-triangle-1-e{
          color: #6d6d67;
        }
      }
      a{
        color: #6d6d67;
        &:before{
          counter-increment: list;
          content: counter(list) ". ";
        }
      }
    }
    .ui-accordion-content{
      border: none;
      margin-bottom: 20px;
      .form-wrapper{
        &.field--type-country{}
        &.field--type-datetime{
          font-size: 0;
          h4{
            color: #6d6d67;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: .3px;
            text-transform: uppercase;
            width: 100%;
            display: block;
            @include respond-to(ipad){
              width: 30%;
              display: inline-block;
              float: left;
            }
          }
          > div{
            display: block;
            width: 100%;
            @include respond-to(ipad){
              display: inline-block;
              width: 70%;
            }
            input{
              width: 100%;
            }
          }
        }
        &.field--name-field-championship{}
        &.field--name-field-team-a{}
        &.field--name-field-team-b{}
        &.field--name-field-comment-tip{
          .form-item{
            label{
              @include respond-to(ipad){
                float: left;
              }
            }
            div{
              width: 100%;
              display: block;
              textarea{
                width: 100%;
                max-height: 180px;
                max-width: 100%;
                height: 100%;
                font-family: Lato,sans-serif;
                font-size: 12px;
                font-weight: 400;
                color: #6d6d67;
                border: 1px solid #9b9b8e;
              }
              @include respond-to(ipad){
                width: 70%;
                display: inline-block;
              }
            }
          }
        }
        .form-item{
          font-size: 0;
          label{
            color: #6d6d67;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: .3px;
            text-transform: uppercase;
            width: 100%;
            display: block;
            margin-bottom: 5px;
            &.visually-hidden{
              position: static!important;
            }
            @include respond-to(ipad){
              margin-bottom: 0;
              width: 30%;
              display: inline-block;
            }
          }
          input{
            font-family: Lato,sans-serif;
            font-size: 12px;
            font-weight: 400;
            color: #6d6d67;
            text-transform: none;
            padding: 5px;
            border: none;
            border-bottom: 1px solid #9b9b8e;
            background: 0 0;
            box-shadow: none;
            width: 100%;
            display: block;
            @include respond-to(ipad){
              width: 70%;
              display: inline-block;
            }
          }
          select{
            font-family: Lato,sans-serif;
            font-size: 12px;
            font-weight: 400;
            color: #6d6d67;
            margin-bottom: 0.75em;
            width: 100%;
            @include respond-to(ipad){
              width: 70%;
            }
          }
          div{
            textarea{
              width: 100%;
              max-height: 180px;
              max-width: 100%;
              height: 100%;
              font-family: Lato,sans-serif;
              font-size: 12px;
              font-weight: 400;
              color: #6d6d67;
              border: 1px solid #9b9b8e;
            }
          }
        }
      }
    }
  }
  .captcha{
    img{}
    .form-item{
      padding: 1em 2.2em;
      position: relative;
      &:before, &:after{
        content: '';
        display: table;
      }
      &:after{
        clear: both;
      }
      label{
        width: 30%;
        float: left;
      }
      input{
        float: left;
        width: 70%;
        font-family: Lato,sans-serif;
        font-weight: 600;
        font-size: 18px;
        text-transform: none;
        padding: 5px;
        border: none;
        border-bottom: 1px solid #9b9b8e;
        background: 0 0;
        box-shadow: none;
        margin-bottom: 0.75em;
      }
      .description{
        position: absolute;
        bottom: 0;
        right: 15px;
        float: none;
      }
    }
  }
  #edit-actions{}
  .captcha{}
}
body{
  &.lang_fa{
    form.node-tip-form{
      .description{
        color: #6d6d67;
        @include respond-to(ipad){
          max-width: 328px;
          margin-left: 0;
          margin-right: auto;
          display: block;
        }
      }
      #edit-field-comment-tip-0-value--description{
        text-align: right;
        @include respond-to(ipad){
          max-width: 328px;
          margin-left: auto;
          margin-right: 0;
          display: block!important;
          text-align: right;
        }
      }
      #edit-field-tip-select-match--description{
        text-align: right;
        @include respond-to(ipad){
          max-width: 328px;
          margin-left: 0;
          margin-right: auto;
          display: block;
        }
      }
    }
  }
}