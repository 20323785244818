.middle_links_wrapper{
  margin: 0 -15px;
  @include respond-to(ipad){}
  @extend .row;
  > div{
    > div{
      overflow: hidden;
      &.page-middle-links{
        overflow: hidden;
        .views-row{
          text-align: center;
          padding: 15px;
          max-width: 290px;
          width: 100%;
          margin: 0 auto;
          @include respond-to(ipad){
            padding: 15px;
            width: auto;
            max-width: 100%;
            margin: 0;
          }
          &.index_timer_0{}
          &.index_timer_1{
            .views-field{
              .field-content{
                a{
                  .event_date{
                    background: rgba(58, 136, 105, .4)!important;
                    .wrap_timer{
                      div{
                        background: rgba(49, 87, 71, .7);
                      }
                    }
                  }
                }
              }
            }
          }
          &.index_timer_2{}
          @include respond-to(desktop){
            text-align: left;
            padding: 0 15px;
            width: 33.333%;
            float: left;
            transform: scale(0.75);
          }
          .views-field{
            .field-content{
              a{
                position: relative;
                opacity: 0.99;
                display: block;
                max-height: 257px;
                height: 257px;
                width: 100%;
                margin: 0 auto;
                max-width: 290px;
                @include respond-to(mobile_ipad){
                  max-height: 257px;
                  position: relative;
                  height: 257px;
                  width: 100%;
                  margin: 0 auto;
                }
                @include respond-to(desktop){
                  max-height: 257px;
                  position: relative;
                  width: 100%;
                  height: 257px;
                  max-width: 100%;
                }
                span{
                  position: absolute;
                  top: 26px;
                  z-index: 3;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 24px;
                  color: #fff;
                  font-family: 'Squada One', cursive;
                  text-transform: uppercase;
                  letter-spacing: -1px;
                  text-align: center;
                  padding: 10px;
                  background: rgba(12, 12, 12, .7);
                  width: 100%;
                  @include respond-to(mobile_ipad){
                    width: 80%;
                  }
                  @include respond-to(desktop){
                    width: 100%;
                    font-size: 30px;
                    top: 35px;
                  }
                }
                img{
                  z-index: 2;
                  max-width: 100%;
                  height: 100%;
                  vertical-align: top;
                  width: 100%;
                  position: static;
                  top: 0;
                  bottom: 0;
                  @include respond-to(mobile_ipad){
                    width: 100%;
                    height: 257px;
                  }
                  @include respond-to(desktop){
                    position: absolute;
                    width: 100%;
                  }
                }
                .event_date{
                  font-size: 14px;
                  color: #fff;
                  z-index: 3;
                  background: rgba(165, 118, 43, .4);
                  position: absolute;
                  right: 0;
                  min-height: 80px;
                  text-align: center;
                  width: 94%;
                  left: 50%;
                  bottom: 10px;
                  transform: translate(-50%, 0);
                  @include respond-to(mobile_ipad){
                    width: 78%;
                  }
                  @include respond-to(desktop){
                    width: 94%;
                  }
                  time{
                    display: none;
                    font-family: 'Ubuntu', sans-serif;
                    font-size: 36px;
                    font-weight: 400;
                    text-transform: uppercase;
                  }
                  .wrap_timer{
                    font-size: 0;
                    padding: 20px 2px;
                    div{
                      position: relative;
                      color: #fff;
                      font-family: 'Ubuntu', sans-serif;
                      font-weight: 400;
                      text-transform: uppercase;
                      display: inline-block;
                      background: rgba(100, 82, 46, .7);
                      margin: 0 20px 0 0;
                      font-size: 28px;
                      line-height: 28px;
                      width: auto;
                      padding: 0 3px;
                      &:last-child{
                        margin: 0;
                      }
                      @include respond-to(ipad){
                        padding: 0;
                        font-size: 36px;
                        line-height: 40px;
                        height: 40px;
                        width: 48px;
                      }
                    }
                    .time_days{
                      &:before{
                        content: 'Days';
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        font-weight: 300;
                        font-family: 'Ubuntu', sans-serif;
                        font-size: 12px;
                        color: #fff;
                        text-transform: none;
                        line-height: normal;
                      }
                      &:after{
                        content: ' : ';
                        display: inline-block;
                        position: absolute;
                        top: 4px;
                        right: -13px;
                        font-size: 24px;
                        line-height: normal;
                      }
                    }
                    .time_hours{
                      &:before{
                        content: 'Hours';
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        font-weight: 300;
                        font-family: 'Ubuntu', sans-serif;
                        font-size: 12px;
                        color: #fff;
                        text-transform: none;
                        line-height: normal;
                      }
                      &:after{
                        content: ' : ';
                        display: inline-block;
                        position: absolute;
                        top: 4px;
                        right: -13px;
                        font-size: 24px;
                        line-height: normal;
                      }
                    }
                    .time_minutes{
                      margin: 0;
                      &:before{
                        content: 'Min';
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        font-weight: 300;
                        font-family: 'Ubuntu', sans-serif;
                        font-size: 12px;
                        color: #fff;
                        text-transform: none;
                        line-height: normal;
                      }
                    }
                    .time_seconds{
                      display: none;
                      margin-left: 20px!important;
                      &:before{
                        content: ' : ';
                        display: inline-block;
                        position: absolute;
                        top: 4px;
                        left: -13px;
                        font-size: 24px;
                        line-height: normal;
                      }
                      &:after{
                        content: 'Sec';
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        font-weight: 300;
                        font-family: 'Ubuntu', sans-serif;
                        font-size: 12px;
                        color: #fff;
                        text-transform: none;
                        line-height: normal;
                      }
                    }
                    .event_done{
                      font-size: 24px;
                      text-transform: uppercase;
                      color: #fff;
                      font-family: 'Ubuntu', sans-serif;
                      background: none!important;
                      width: 100%;
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
body{
  &.lang_fa{
    .middle_links_wrapper{
      > div{
        > div{
          overflow: hidden;
          &.page-middle-links{
            overflow: hidden;
            .views-row{
              @include respond-to(desktop){
                padding: 0 15px;
                width: 33.333%;
                text-align: right;
                float: right;
                unicode-bidi: bidi-override;
                direction: rtl;
              }
              .views-field{
                .field-content{
                  a{
                    .event_date{
                      transform: translate(-3%, 0);
                      @include respond-to(mobile_ipad){
                        transform: translate(-14%, 0);
                      }
                      @include respond-to(desktop){
                        transform: translate(-3%, 0);
                      }
                      .wrap_timer{
                        &:before, &:after{
                          content: '';
                          display: table;
                        }
                        &:after{
                          clear: both;
                        }
                        > div{
                          //float: right;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
