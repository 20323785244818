body.path-odds-calculator{
  #block-qarea-content{
    background-color: #fff;
    max-width: 790px;
    margin: 0 auto;
    width: 100%;
    padding: 20px 0;
    .inputTable{
      max-width: 620px;
      padding: 0 25px;
      margin: 0 auto;
      width: 100%;
      form{
        table{
          width: 100%;
          max-width: 495px;
          margin: 0 auto;
          table-layout: fixed;
          tr{
            td{
              vertical-align: middle;
              color: #6d6d67;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0.3px;
              text-transform: uppercase;
              padding: 5px 0;
              width: 34%;
              input[type=text]{
                width: 100%;
                font-family: Lato,sans-serif;
                font-size: 22px;
                font-weight: 700;
                color: #6d6d67;
                text-transform: uppercase;
                float: left;
                padding: 5px;
                border: none;
                border-bottom: 1px solid #9b9b8e;
                background: 0 0;
                box-shadow: none;
                margin: 0;
                &:focus{
                  border-bottom: 1px solid #ffad10;
                }
              }
              &:first-child{
                width: 40%;
              }
              &:last-child{
                width: 26%;
                padding-left: 25px;
              }
            }
            &:last-child{
              td{
                text-align: center!important;
                width: 50%;
                &:first-child{
                  input[type=button]{
                    background: none;
                    border: none;
                    font-family: Lato,sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    color: #6d6d67;
                    text-transform: uppercase;
                    @include transition(all 200ms ease-in-out);
                    &:hover{
                      color: #ffad10;
                    }
                  }
                }
                &:last-child{
                  padding: 20px 0;
                  input[type=button]{
                    @extend .button;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}