body.path-user{
  #block-qarea-content{
    form.user-form{
      position: relative;
      background-color: #fff;
      padding: 20px 25px 40px;
      @include respond-to(ipad){
        //padding: 100px 25px;
      }
      &.user-register-form{
        position: relative;
        background-color: #fff;
        padding: 20px 25px 40px;
        @include respond-to(ipad){
          //padding: 100px 25px;
        }
        #edit-actions{
          margin: 20px auto 0;
        }
      }
      > div{
        max-width: 410px;
        margin: 0 auto;
        width: 100%;
        padding: 10px 0;
        color: #6d6d67;
        &.js-form-type-checkbox{
          position: initial;
          width: 100%;
          margin: 0 auto;
          @include respond-to(ipad){
          
          }
          input{
            width: auto;
            float: left;
            margin: 0 5px 0 0;
            position: relative;
            z-index: 999;
          }
          label{
            width: auto;
            display: block;
            &.option{
              position: relative;
              &:after{
                content: '(*)';
                position: absolute;
                font-size: 10px;
                text-transform: none;
                color: red;
                font-family: monospace;
                top: 0;
                right: -17px;
              }
            }
          }
        }
      }

      .form-item{
        font-size: 0;
        margin: 0 0 5px 0;
        position: relative;
        &:last-child{
          margin: 0;
        }
        label{
          position: relative;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 400;
          font-family: sans-serif;
          letter-spacing: -0.5px;
          width: 100%;
          display: block;
          //padding-right: 55px;
          @include respond-to(ipad){
            width: 40%;
            display: inline-block;
          }
        }
        input[type=password],
        input[type=text],
        input[type=tel],
        input[type=email]{
          font-size: 22px;
          text-transform: uppercase;
          font-weight: 700;
          margin: 0;
          font-family: Lato,sans-serif;
          color: #6d6d67;
          padding: 5px;
          border: none;
          border-bottom: 1px solid #9b9b8e;
          background: 0 0;
          box-shadow: none;
          width: 100%;
          display: block;
          @include respond-to(ipad){
            width: 60%;
            display: inline-block;
          }
        }
        .description{
          display: block;
          margin-top: 10px;
          font-size: 12px;
          a{
            font-size: 12px;
            color: #ffad10;
          }
        }
        .image-widget{
          input[type=file]{
            width: 100%;
            @include respond-to(ipad){
              width: auto;
            }
          }
        }
        .text-full{
          &:disabled{
            opacity: 0.3;
          }
        }
      }

      .form-item-current-pass{
        .form-item{
          label{}
          input{}
          .description{}
        }
      }
      #edit-pass{
        .form-item{
          label{}
          input{}
        }
        .password-confirm{}
      }
      .field--name-field-country{
        .form-item{
          label{}
          input{}
        }
      }
      .field--name-user-picture{
        .form-item{
          label{}
          .image-widget{}
          .description{}
        }
      }
      .field--name-field-user-id{
        .form-item{
          label{}
          .image-widget{}
          .description{}
        }
      }
      .field--name-field-phone{
        .form-item{
          label{}
          input{}
          .description{}
        }
      }
      #edit-actions{
        input{
          &:first-child{
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
}
.reg_required{
  font-size: 10px;
  text-transform: none;
  color: red;
  font-family: monospace;
  //padding: 0 5px;
  position: absolute;
  top: 23px;
  right: -17px;
  @include respond-to(ipad){
    //top: -15px;
  }
}
span.all_required {
  margin-bottom: 10px;
  font-size: 12px;
  color: red;
  display: inline-block;
  width: 100%;
}
body.path-user #block-qarea-content form.user-form.user-register-form #edit-actions {
  margin: 0px auto 0;
}
