#footer{
  text-align: center;
  padding: 40px 0 60px;
  //background-color: #363532;
  @include respond-to(ipad){
    padding: 25px 0 60px;
  }
  .container{
    div{
      &.partners-of-masters-wrapper{
        > h2{
          margin: 0 0 20px 0;
        }
      }
      h2{
        font-size: 24px;
        font-family: Lato, sans-serif;
        color: #fff;
        font-weight: 700;
        border-bottom: 4px solid #ffad10;
        display: inline-block;
        //margin: 40px 0;
        text-transform: uppercase;
        padding: 0 0 5px 0;
      }
    }
  }
  .partners_list{
    font-size: 0;
    li{
      display: inline-block;
      padding: 15px;
      a{
        display: block;
        background-color: #fff;
        border-radius: 100%;
        width: 65px;
        height: 65px;
        text-align: center;
        position: relative;
        opacity: 0.99;
        img{
          display: inline-block;
          width: auto;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
